import React from 'react';
import { formatDistanceToNow } from 'date-fns';
import { RefreshCw } from 'lucide-react';

const TournamentLeaderboard = ({ leaderboard, lastUpdate, isLoading, onRefresh }) => {
  const formatScore = (score) => {
    if (!score || score === '-') return '-';
    if (score === 'E') return 'E';
    return score.startsWith('+') ? score : score.startsWith('-') ? score : `+${score}`;
  };

  const getScoreColor = (score) => {
    if (!score || score === '-' || score === 'E') return 'text-gray-700';
    return score.startsWith('-') ? 'text-green-600' : 'text-red-600';
  };

  const formatHole = (hole) => {
    if (!hole || hole === '-') return 'Yet to start';
    if (hole === 'F') return 'F';
    if (hole === 'CUT') return 'CUT';
    if (hole === 'WD') return 'WD';
    if (hole.includes(':')) return `Tee: ${hole}`;
    return `Thru ${hole}`;
  };

  const getRowStyle = (player) => {
    if (player.currentHole === 'CUT') return 'bg-gray-50';
    if (player.currentHole === 'WD') return 'bg-gray-50';
    return '';
  };

  const formatLastUpdate = (timestamp) => {
    if (!timestamp) return 'Never updated';
    try {
      const date = new Date(timestamp);
      if (isNaN(date.getTime())) return 'Invalid date';
      return `Updated ${formatDistanceToNow(date, { addSuffix: true })}`;
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Date error';
    }
  };

  if (!leaderboard || leaderboard.length === 0) {
    return (
      <div className="bg-white rounded-lg shadow-sm p-6 text-center text-gray-500">
        No leaderboard data available
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-sm">
      <div className="border-b border-gray-200 px-4 py-3 flex justify-between items-center">
        <h2 className="text-lg font-bold">Tournament Leaderboard</h2>
        <div className="flex items-center gap-2 text-sm text-gray-500">
          <span>{formatLastUpdate(lastUpdate)}</span>
          {onRefresh && (
            <button 
              onClick={onRefresh}
              className="p-1 hover:bg-gray-100 rounded-full"
              disabled={isLoading}
            >
              <RefreshCw className={`w-4 h-4 ${isLoading ? 'animate-spin' : ''}`} />
            </button>
          )}
        </div>
      </div>

      {/* Desktop view */}
      <div className="hidden md:block">
        <table className="w-full">
          <thead>
            <tr className="border-b border-gray-100 bg-gray-50">
              <th className="text-left py-3 px-4 text-sm font-medium text-gray-600">POS</th>
              <th className="text-left py-3 px-4 text-sm font-medium text-gray-600">PLAYER</th>
              <th className="text-center py-3 px-4 text-sm font-medium text-gray-600">TOTAL</th>
              <th className="text-center py-3 px-4 text-sm font-medium text-gray-600">TODAY</th>
              <th className="text-right py-3 px-4 text-sm font-medium text-gray-600">THRU</th>
            </tr>
          </thead>
          <tbody>
            {leaderboard.map((player, index) => (
              <tr 
                key={player._id || index} 
                className={`border-b border-gray-100 hover:bg-gray-50 transition-colors ${getRowStyle(player)}`}
              >
                <td className="py-3 px-4 font-medium">
                  {player.tournamentPosition || '-'}
                </td>
                <td className="py-3 px-4">
                  <span className="font-medium">{player.name}</span>
                </td>
                <td className={`py-3 px-4 text-center font-medium ${getScoreColor(player.tournamentScore)}`}>
                  {formatScore(player.tournamentScore)}
                </td>
                <td className={`py-3 px-4 text-center ${getScoreColor(player.roundScore)}`}>
                  {formatScore(player.roundScore)}
                </td>
                <td className="py-3 px-4 text-right text-sm text-gray-600">
                  {formatHole(player.currentHole)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Mobile view */}
      <div className="md:hidden divide-y divide-gray-100">
        {leaderboard.map((player, index) => (
          <div 
            key={player._id || index} 
            className={`px-4 py-3 ${getRowStyle(player)}`}
          >
            <div className="flex justify-between items-center">
              <div className="flex items-center gap-2">
                <span className="font-medium w-8">
                  {player.tournamentPosition || '-'}
                </span>
                <span className="font-medium">{player.name}</span>
              </div>
              <span className={`font-medium ${getScoreColor(player.tournamentScore)}`}>
                {formatScore(player.tournamentScore)}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TournamentLeaderboard;