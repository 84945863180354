import { useState, useEffect } from 'react';
import { golfAdminService } from '../../api/services';
import useGolfAdminBase from './useGolfAdminBase';

export const useLeagueManagement = () => {
  const { loading, error, syncMessage, setLoading, setError, setSyncMessage, handleError } = useGolfAdminBase();
  const [leagues, setLeagues] = useState([]);
  const [newLeagueName, setNewLeagueName] = useState('');

  useEffect(() => {
    loadLeagues();
  }, []);

  const loadLeagues = async () => {
    try {
      setLoading(true);
      setError(null);
      
      // Get the current game first
      const gameResponse = await golfAdminService.getCurrentGame();
      console.log("Current game response:", gameResponse);
      
      const gameId = gameResponse?.data?.currentGame?._id || gameResponse?.currentGame?._id;
      console.log("Game ID for leagues:", gameId);
      
      if (!gameId) {
        console.log("No game ID found for leagues");
        setLeagues([]);
        return;
      }
      
      // Try direct API call to debug
      const leagues = await golfAdminService.getLeaguesForGame(gameId);
      console.log("Leagues data received:", leagues);
      
      setLeagues(Array.isArray(leagues) ? leagues : []);
    } catch (err) {
      handleError(err, 'Failed to fetch leagues');
      setLeagues([]); // Set empty array on error
    } finally {
      setLoading(false);
    }
  };

  const createLeague = async (e) => {
    e.preventDefault();
    try {
      setSyncMessage('Creating league...');
      await golfAdminService.createLeague(newLeagueName);
      setSyncMessage('League created successfully!');
      setNewLeagueName('');
      await loadLeagues();
    } catch (err) {
      handleError(err, 'Failed to create league');
    }
  };

  return {
    leagues,
    loading,
    error,
    syncMessage,
    newLeagueName,
    setNewLeagueName,
    createLeague,
    refreshLeagues: loadLeagues  // Renamed to match pattern in other hooks
  };
};

export default useLeagueManagement;