import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { resetPasswordLoggedIn } from '../api/auth';
import { Input, Button, FormMessage } from '../components/ui/form';

function Profile() {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setMessage('');
    
    if (newPassword !== confirmPassword) {
      setMessage('Passwords do not match');
      return;
    }
    
    try {
      setIsLoading(true);
      await resetPasswordLoggedIn(newPassword);
      setMessage('Password reset successfully');
      setNewPassword('');
      setConfirmPassword('');
    } catch (error) {
      setMessage('Failed to reset password. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="container mx-auto px-4">
        <div className="max-w-5xl mx-auto">
          <div className="bg-white rounded-lg shadow-sm p-6">
            <div className="flex justify-between items-center mb-6">
              <h1 className="text-2xl font-bold">Profile</h1>
              <Button 
                variant="danger"
                onClick={handleLogout}
              >
                Logout
              </Button>
            </div>
            
            <div className="mb-6 space-y-2">
              <p><span className="font-semibold">Email:</span> {user.email}</p>
              <p><span className="font-semibold">Display Name:</span> {user.displayName}</p>
            </div>
            
            <div className="border-t pt-6">
              <h2 className="text-xl font-bold mb-4">Reset Password</h2>
              <form onSubmit={handleResetPassword} className="space-y-4">
                <Input
                  id="newPassword"
                  type="password"
                  label="New Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                />

                <Input
                  id="confirmPassword"
                  type="password"
                  label="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />

                <Button
                  type="submit"
                  isLoading={isLoading}
                  className="w-full"
                >
                  Reset Password
                </Button>
              </form>

              {message && (
                <FormMessage 
                  type={message.includes('successfully') ? 'success' : 'error'}
                  className="mt-4"
                >
                  {message}
                </FormMessage>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;