import React from 'react';
import { Trophy } from 'lucide-react';
import LoadingSpinner from '../../ui/state/LoadingSpinner';
import LeaderboardCard from '../shared/LeaderboardCard';
import MobileEntry from '../shared/MobileEntry';

const GameLeaderboard = ({ leaderboard, isLoading, onRefresh, isDeadlinePassed }) => {
  if (!isDeadlinePassed) {
    return (
      <LeaderboardCard title="Game Leaderboard" icon={Trophy}>
        <div className="p-4">
          <p className="text-gray-600">
            The game leaderboard will be available after the tournament starts.
          </p>
        </div>
      </LeaderboardCard>
    );
  }

  if (isLoading) {
    return (
      <div className="flex justify-center py-4">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <LeaderboardCard 
      title="Game Leaderboard" 
      icon={Trophy} 
      onRefresh={onRefresh}
      className="bg-gradient-to-br from-white to-blue-50"
    >
      {/* Mobile View */}
      <div className="md:hidden divide-y divide-gray-100">
        {leaderboard.map((entry, index) => (
          <div key={entry._id} className="p-4">
            <div className="flex items-center justify-between mb-3">
              <div className="flex items-center gap-3">
                <span className="flex items-center justify-center w-8 h-8 rounded-full bg-blue-100 text-blue-600 font-semibold">
                  {index + 1}
                </span>
                <span className="font-medium text-gray-900">{entry.user.displayName}</span>
              </div>
              <span className={`text-lg font-bold ${
                entry.totalScore < 0 ? "text-green-600" : 
                entry.totalScore > 0 ? "text-red-600" : "text-gray-600"
              }`}>
                {entry.totalScore}
              </span>
            </div>
            <div className="space-y-2 bg-gray-50 rounded-lg p-3">
              {entry.selections.map((selection, i) => (
                <div key={i} className="flex justify-between items-center">
                  <span className="text-sm text-gray-600">{selection.name}</span>
                  <span className={`font-medium ${
                    selection.tournamentScore === "CUT" ? "text-red-500" :
                    selection.tournamentScore.startsWith("-") ? "text-green-600" : "text-red-600"
                  }`}>
                    {selection.tournamentScore}
                  </span>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      {/* Desktop View */}
      <div className="hidden md:block">
        <table className="w-full">
          <thead>
            <tr className="bg-blue-500/5">
              <th className="px-4 py-3 text-left w-12 text-sm font-semibold text-blue-700">POS</th>
              <th className="px-4 py-3 text-left text-sm font-semibold text-blue-700">PLAYER</th>
              <th className="px-4 py-3 text-left text-sm font-semibold text-blue-700">SELECTIONS</th>
              <th className="px-4 py-3 text-right w-24 text-sm font-semibold text-blue-700">SCORE</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-100">
            {leaderboard.map((entry, index) => (
              <tr key={entry._id}>
                <td className="px-4 py-3">
                  <span className="text-blue-600 font-semibold text-lg">{index + 1}</span>
                </td>
                <td className="px-4 py-3">
                  <span className="font-medium text-gray-900">{entry.user.displayName}</span>
                </td>
                <td className="px-4 py-3">
                  <div className="space-y-1.5">
                    {entry.selections.map((selection, i) => (
                      <div key={i} className="flex justify-between text-sm max-w-md px-2 py-1">
                        <span className="text-gray-600">{selection.name}</span>
                        <span className={`font-medium ${
                          selection.tournamentScore === "CUT" ? "text-red-500" :
                          selection.tournamentScore.startsWith("-") ? "text-green-600" : "text-red-600"
                        }`}>
                          {selection.tournamentScore}
                        </span>
                      </div>
                    ))}
                  </div>
                </td>
                <td className="px-4 py-3 text-right">
                  <span className={`text-xl font-bold ${
                    entry.totalScore < 0 ? "text-green-600" :
                    entry.totalScore > 0 ? "text-red-600" : "text-gray-600"
                  }`}>
                    {entry.totalScore}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </LeaderboardCard>
  );
};

export default GameLeaderboard;