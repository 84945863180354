import React from 'react';
import { Trophy } from 'lucide-react';
import LeaderboardCard from '../../golf/shared/LeaderboardCard';
import { LoadingSpinner } from '../../ui/state';

const Leaderboard = ({ 
  data, 
  isLoading, 
  onRefresh, 
  title = "Leaderboard",
  includeGameweek = false 
}) => {
  console.log('Leaderboard received data:', data);  // Add this line
  
  if (isLoading) {
    return (
      <LeaderboardCard title={title} icon={Trophy}>
        <div className="p-4 flex justify-center">
          <LoadingSpinner />
        </div>
      </LeaderboardCard>
    );
  }

  return (
    <LeaderboardCard 
      title={title} 
      icon={Trophy}
      onRefresh={onRefresh}
    >
      <div className="divide-y divide-gray-100">
        {data.map((entry, index) => (
          <div key={entry._id || index} className="p-4">
            <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
  <span className="flex items-center justify-center w-8 h-8 
    rounded-full bg-orange-100 text-orange-600 font-semibold">
    {index + 1}
  </span>
  <div>
    <span className="font-medium text-primary-600">{entry.user}</span>
    {includeGameweek && (
      <span className="text-sm text-primary-400 ml-2">
        ({entry.gameweek})
      </span>
    )}
  </div>
</div>
<span className="text-lg font-bold text-primary-600">{entry.score}</span>
            </div>
          </div>
        ))}

        {data.length === 0 && (
          <div className="p-4 text-center text-gray-500">
            No data available
          </div>
        )}
      </div>
    </LeaderboardCard>
  );
};

export default Leaderboard;
