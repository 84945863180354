import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function LoginPrompt() {
  const location = useLocation();
  
  return (
    <div className="max-w-md mx-auto my-10 p-6 bg-gray-50 rounded-lg shadow-md text-center">
      <h2 className="text-purple-800 text-xl font-semibold mb-5">Login Required</h2>
      <p className="mb-5">You need to be logged in to access this page.</p>
      <div className="flex justify-center gap-5">
        <Link 
          to="/login" 
          state={{ from: location }} 
          className="px-5 py-2.5 bg-purple-600 text-white rounded hover:bg-purple-700 transition-colors"
        >
          Login
        </Link>
        <Link 
          to="/register" 
          state={{ from: location }} 
          className="px-5 py-2.5 bg-purple-600 text-white rounded hover:bg-purple-700 transition-colors"
        >
          Register
        </Link>
      </div>
    </div>
  );
}

export default LoginPrompt;