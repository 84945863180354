import React from 'react';
import { cn } from '../../../utils/cn';

const FormMessage = ({ 
  type = 'success',
  children,
  className 
}) => {
  const variants = {
    success: 'bg-green-50 text-green-700 border-green-200',
    error: 'bg-red-50 text-red-700 border-red-200',
    warning: 'bg-yellow-50 text-yellow-700 border-yellow-200',
    info: 'bg-blue-50 text-blue-700 border-blue-200'
  };

  return (
    <div className={cn(
      'p-3 rounded-md border',
      variants[type],
      className
    )}>
      {children}
    </div>
  );
};

export default FormMessage;