import { useState, useEffect } from 'react';
import { rugbyAdminService } from '../../api/services';
import useRugbyBase from './useRugbyBase';

const useRugbyManagement = () => {
  const { loading, error, syncMessage, setLoading, setError, setSyncMessage, handleError } = useRugbyBase();
  const [currentGame, setCurrentGame] = useState(null);

  useEffect(() => {
    fetchCurrentGame();
  }, []);

  const fetchCurrentGame = async () => {
    try {
      setLoading(true);
      setError(null);
      
      const response = await rugbyAdminService.getCurrentGame();
      console.log('Game data received:', response);
      
      if (!response || !response.currentGame) {
        setCurrentGame(null);
        return;
      }
      
      setCurrentGame(response.currentGame);
    } catch (err) {
      handleError(err, 'Failed to fetch current game');
    } finally {
      setLoading(false);
    }
  };

  const createGame = async (gameData) => {
    try {
      setSyncMessage('Creating new gameweek...');
      const response = await rugbyAdminService.createGame(gameData);
      await fetchCurrentGame();
      setSyncMessage('Gameweek created successfully!');
      return response;
    } catch (err) {
      handleError(err, 'Failed to create gameweek');
    }
  };

  return {
    currentGame,
    loading,
    error,
    syncMessage,
    createGame,
    refreshGame: fetchCurrentGame
  };
};

export default useRugbyManagement;