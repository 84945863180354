import BaseApi, { API_ENDPOINTS } from '../base/baseApi';

class AuthService extends BaseApi {

  async login(email, password) {
    try {
      const response = await this.post(API_ENDPOINTS.auth.login, { 
        email, 
        password 
      });

      if (response.token) {
        localStorage.setItem('token', response.token);
      }

      return response;
    } catch (error) {
      // Generic error message without exposing details
      console.error('Authentication failed');
      throw error;
    }
  }

  async register(displayName, email, password) {
    try {
      const response = await this.post(API_ENDPOINTS.auth.register, { 
        displayName, 
        email, 
        password 
      });
      return response;
    } catch (error) {
      console.error('Registration failed');
      throw error;
    }
  }

  async getCurrentUser() {
    return this.get(API_ENDPOINTS.auth.user);
  }

  async forgotPassword(email) {
    try {
      return await this.post(API_ENDPOINTS.auth.forgotPassword, { 
        email 
      });
    } catch (error) {
      console.error('Password reset request failed');
      throw error;
    }
  }

  async resetPassword(token, newPassword) {
    try {
      return await this.post(`${API_ENDPOINTS.auth.resetPassword}/${token}`, { 
        password: newPassword 
      });
    } catch (error) {
      console.error('Password reset failed');
      throw error;
    }
  }

  async resetPasswordLoggedIn(newPassword) {
    try {
      return await this.post(API_ENDPOINTS.auth.resetPassword, { 
        newPassword 
      });
    } catch (error) {
      console.error('Password update failed');
      throw error;
    }
  }

  logout() {
    localStorage.removeItem('token');
  }
}

const authService = new AuthService();
export default authService;