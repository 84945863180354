import { useState } from 'react';
import { golfAdminService } from '../../api/services';
import useGolfAdminBase from './useGolfAdminBase';

export const useDataSync = () => {
  const { loading, error, syncMessage, setError, setSyncMessage, handleError } = useGolfAdminBase();
  const [lastSyncTime, setLastSyncTime] = useState(null);
  const [lastAppendTime, setLastAppendTime] = useState(null);
  const [syncResults, setSyncResults] = useState(null);
  const [appendResults, setAppendResults] = useState(null);

  const syncTournamentData = async () => {
    try {
      setSyncMessage('Syncing tournament data...');
      const response = await golfAdminService.syncTournament();
      setLastSyncTime(new Date());
      setSyncResults(response);
      setSyncMessage('Tournament data synced successfully!');
      return response;
    } catch (err) {
      handleError(err, 'Failed to sync tournament data');
    }
  };
  
  const syncPlayers = async () => {
    try {
      setSyncMessage('Syncing all players...');
      const response = await golfAdminService.syncPlayers();
      setLastSyncTime(new Date());
      setSyncResults(response);
      setSyncMessage('Players synced successfully!');
      return response;
    } catch (err) {
      handleError(err, 'Failed to sync players');
    }
  };
  
  const appendPlayers = async () => {
    try {
      setSyncMessage('Appending new players...');
      const response = await golfAdminService.appendPlayers();
      setLastAppendTime(new Date());
      setAppendResults(response);
      
      // Create friendly message about results
      const data = response?.data || {};
      const message = `Added ${data.playersCreated || 0} new players, skipped ${data.playersSkipped || 0} existing players.`;
      setSyncMessage(message);
      
      return response;
    } catch (err) {
      handleError(err, 'Failed to append new players');
    }
  };

  return {
    lastSyncTime,
    lastAppendTime,
    loading,
    error,
    syncMessage,
    syncResults,
    appendResults,
    syncTournamentData,
    syncPlayers,
    appendPlayers
  };
};

export default useDataSync;