import React, { useState } from 'react';
import { RefreshCw, Plus, Trash, AlertTriangle } from 'lucide-react';
import Card from '../shared/Card';
import usePlayerMapping from '../../../hooks/golf/usePlayerMapping';
import LoadingSpinner from '../../ui/state/LoadingSpinner';

const PlayerMapping = () => {
  const {
    mappings,
    unmatchedNames,
    loading,
    error,
    syncMessage,
    addMapping,
    removeMapping,
    refreshMappings
  } = usePlayerMapping();

  const [newMapping, setNewMapping] = useState({
    canonicalName: '',
    variations: ''
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const variations = newMapping.variations.split(',').map(v => v.trim());
      await addMapping(newMapping.canonicalName, variations);
      setNewMapping({ canonicalName: '', variations: '' });
    } catch (err) {
      // Error is handled by the hook
    }
  };

  const handleQuickAdd = (unmatchedName) => {
    setNewMapping(prev => ({
      ...prev,
      variations: prev.variations 
        ? `${prev.variations}, ${unmatchedName}` 
        : unmatchedName
    }));
  };

  if (loading) return <LoadingSpinner />;

  return (
    <div className="space-y-6">
      {error && (
        <div className="bg-red-50 border border-red-200 text-red-700 p-4 rounded-lg">
          {error}
        </div>
      )}

      {syncMessage && (
        <div className="bg-green-50 border border-green-200 text-green-700 p-4 rounded-lg">
          {syncMessage}
        </div>
      )}

      {unmatchedNames.length > 0 && (
        <Card>
          <div className="flex items-start gap-3 text-yellow-800">
            <AlertTriangle className="w-5 h-5 mt-0.5 flex-shrink-0" />
            <div>
              <h3 className="font-semibold mb-2">Unmatched Players Detected</h3>
              <div className="space-y-2">
                {unmatchedNames.map((name, index) => (
                  <div 
                    key={index}
                    className="flex items-center justify-between bg-yellow-50 p-2 rounded"
                  >
                    <span>{name}</span>
                    <button
                      onClick={() => handleQuickAdd(name)}
                      className="text-sm text-yellow-800 hover:text-yellow-900"
                    >
                      Add to mapping
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Card>
      )}

      <Card>
        <div className="flex justify-between items-center mb-6">
          <div>
            <h2 className="text-lg font-semibold">Player Name Mappings</h2>
            <p className="text-sm text-gray-500 mt-1">
              Manage name variations for player data synchronization
            </p>
          </div>
          <button
            onClick={refreshMappings}
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
            title="Refresh mappings"
          >
            <RefreshCw className="w-5 h-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4 mb-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Canonical Name
            </label>
            <input
              type="text"
              value={newMapping.canonicalName}
              onChange={(e) => setNewMapping(prev => ({
                ...prev,
                canonicalName: e.target.value
              }))}
              className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500/20 focus:border-blue-500"
              placeholder="e.g., Christopher Gotterup"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Name Variations (comma-separated)
            </label>
            <input
              type="text"
              value={newMapping.variations}
              onChange={(e) => setNewMapping(prev => ({
                ...prev,
                variations: e.target.value
              }))}
              className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500/20 focus:border-blue-500"
              placeholder="e.g., Chris Gotterup, C. Gotterup"
              required
            />
          </div>

          <button
            type="submit"
            className="w-full flex items-center justify-center gap-2 bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors"
          >
            <Plus className="w-4 h-4" />
            Add Mapping
          </button>
        </form>

        <div className="border-t pt-6">
          <h3 className="font-medium mb-4">Current Mappings</h3>
          <div className="space-y-3">
            {mappings.map((mapping, index) => (
              <div 
                key={index}
                className="flex items-start justify-between p-3 bg-gray-50 rounded-lg group hover:bg-gray-100"
              >
                <div>
                  <div className="font-medium">{mapping.canonicalName}</div>
                  <div className="text-sm text-gray-600 mt-1">
                    Variations: {mapping.variations.join(', ')}
                  </div>
                </div>
                <button
                  onClick={() => removeMapping(mapping.canonicalName)}
                  className="p-2 text-gray-400 hover:text-red-500 opacity-0 group-hover:opacity-100 transition-opacity"
                  title="Remove mapping"
                >
                  <Trash className="w-4 h-4" />
                </button>
              </div>
            ))}

            {mappings.length === 0 && (
              <div className="text-center py-8 text-gray-500">
                No mappings defined yet. Add a mapping to get started.
              </div>
            )}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default PlayerMapping;