import React, { useState, useEffect, useCallback } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const AdStrip = ({ ads, type = 'banner', className = '' }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleAds, setVisibleAds] = useState(3);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    console.log('Ad data:', ads);
  }, [ads]);

  // Auto-rotation for banner ads
  useEffect(() => {
    if (type === 'banner' && ads.length > 1 && !isHovered) {
      const timer = setInterval(() => {
        setCurrentIndex((prev) => (prev + 1) % ads.length);
      }, 5000);
      return () => clearInterval(timer);
    }
  }, [ads.length, type, isHovered]);

  // Responsive handling
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 640) {
        setVisibleAds(1);
      } else if (window.innerWidth < 1024) {
        setVisibleAds(2);
      } else {
        setVisibleAds(3);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleAdClick = useCallback((ad) => {
    try {
      if (ad.trackingCode) {
        console.log('Ad clicked:', ad.trackingCode);
      }
    } catch (error) {
      console.error('Error tracking ad click:', error);
    }
  }, []);

  if (!ads || ads.length === 0) return null;

  const getImageUrl = (image) => {
    if (!image) return null;
    
    // Handle different possible Strapi image structures
    if (image.data && image.data.attributes) {
      return `${process.env.REACT_APP_STRAPI_URL}${image.data.attributes.url}`;
    }
    if (image.url) {
      return `${process.env.REACT_APP_STRAPI_URL}${image.url}`;
    }
    return null;
  };

  // Banner style
  if (type === 'banner') {
    return (
      <div 
        className={`bg-[#12141d] rounded-lg ${className}`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {ads.map((ad, index) => (
          <div
            key={ad.id}
            className={`transition-all duration-500 ${
              index === currentIndex ? 'opacity-100' : 'opacity-0 absolute inset-0'
            }`}
          >
            <div className="flex items-center justify-between h-14 px-4">
              <div className="flex items-center gap-4 flex-1 min-w-0">
                <h3 className="text-xl font-bold text-white truncate">
                  {ad.title}
                </h3>
                <p className="text-sm text-gray-300 truncate hidden md:block flex-1">
                  {ad.description}
                </p>
              </div>
              <a
                href={ad.affiliateLink}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => handleAdClick(ad)}
                className="shrink-0 px-6 py-2 bg-white rounded font-bold text-sm hover:bg-gray-100 transition-colors"
              >
                CLAIM OFFER
              </a>
            </div>
          </div>
        ))}
      </div>
    );
  }

  // Card carousel style
  return (
    <div className={`relative max-w-7xl mx-auto ${className}`}>
      <div className="px-4">
        <div className="relative">
          <div className="overflow-hidden">
            <div 
              className="flex transition-all duration-300 ease-in-out gap-6"
              style={{ 
                transform: `translateX(-${(currentIndex * 100) / visibleAds}%)` 
              }}
            >
              {ads.map(ad => {
                const imageUrl = getImageUrl(ad.image);
                
                return (
                  <div 
                    key={ad.id}
                    className="flex-none w-full sm:w-1/2 lg:w-1/3"
                  >
                    <a
                      href={ad.affiliateLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => handleAdClick(ad)}
                      className="block bg-gray-50 border border-gray-100 rounded-lg overflow-hidden hover:border-gray-200 transition-all duration-300"
                    >
                      {imageUrl && (
                        <div className="h-24 relative overflow-hidden bg-white border-b border-gray-100">
                          <img
                            src={imageUrl}
                            alt={ad.title}
                            className="w-full h-full object-contain p-2"
                            loading="lazy"
                          />
                        </div>
                      )}
                      <div className="p-4 bg-gradient-to-br from-gray-50 to-white">
                        <div className="flex justify-between items-start gap-4">
                          <div>
                            <h3 className="font-bold text-gray-900 mb-1">
                              {ad.title}
                            </h3>
                            <p className="text-sm text-gray-600 line-clamp-2">
                              {ad.description}
                            </p>
                          </div>
                          <div className="shrink-0">
                            <span className="inline-block px-4 py-2 bg-[#12141d] text-white text-sm font-medium rounded-full hover:bg-gray-800 transition-colors">
                              Get Offer
                            </span>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                );
              })}
            </div>
          </div>

          {/* Navigation arrows - only show if we have more ads than visible slots */}
          {ads.length > visibleAds && (
            <>
              <button
                onClick={() => setCurrentIndex(Math.max(0, currentIndex - 1))}
                className={`absolute left-0 top-1/2 -translate-y-1/2 -translate-x-3 bg-white/90 backdrop-blur-sm rounded-full p-2 shadow-lg hover:bg-white transition-opacity ${
                  currentIndex === 0 ? 'opacity-0 pointer-events-none' : 'opacity-100'
                }`}
                disabled={currentIndex === 0}
              >
                <ChevronLeft className="w-5 h-5 text-gray-800" />
              </button>
              <button
                onClick={() => setCurrentIndex(Math.min(ads.length - visibleAds, currentIndex + 1))}
                className={`absolute right-0 top-1/2 -translate-y-1/2 translate-x-3 bg-white/90 backdrop-blur-sm rounded-full p-2 shadow-lg hover:bg-white transition-opacity ${
                  currentIndex >= ads.length - visibleAds ? 'opacity-0 pointer-events-none' : 'opacity-100'
                }`}
                disabled={currentIndex >= ads.length - visibleAds}
              >
                <ChevronRight className="w-5 h-5 text-gray-800" />
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdStrip;