import { useState, useEffect } from 'react';
import { golfAdminService } from '../../api/services';
import useGolfAdminBase from './useGolfAdminBase';

const usePlayerManagement = () => {
  const [players, setPlayers] = useState([]);
  const { loading, error, syncMessage, setError, setSyncMessage, handleError } = useGolfAdminBase();

  const fetchPlayers = async () => {
    try {
      const response = await golfAdminService.getAvailablePlayers();
      setPlayers(response || []);
    } catch (err) {
      handleError(err, 'Failed to fetch players');
    }
  };

  const refreshPlayers = async () => {
    try {
      setSyncMessage('Refreshing players...');
      await fetchPlayers();
      setSyncMessage('Players refreshed successfully!');
    } catch (err) {
      handleError(err, 'Failed to refresh players');
    }
  };

  const updatePlayerOdds = async (playerId, odds) => {
    try {
      await golfAdminService.updatePlayerOdds(playerId, odds);
      
      // Update local state to reflect the change
      setPlayers(prevPlayers => 
        prevPlayers.map(player => 
          player._id === playerId ? { ...player, odds } : player
        )
      );
    } catch (err) {
      handleError(err, 'Failed to update player odds');
    }
  };

  useEffect(() => {
    fetchPlayers();
  }, []);

  return {
    players,
    loading,
    error, 
    syncMessage,
    updatePlayerOdds,
    refreshPlayers
  };
};

export default usePlayerManagement;