import React from 'react';
import { Check, Loader2 } from 'lucide-react';
import Card from '../../shared/Card';
import useScoreManagement from '../../../../hooks/football/useScoreManagement';
import useFootballBase from '../../../../hooks/football/useFootballBase';
import { LoadingSpinner } from '../../../../components/ui/state';

const ScoreManagement = () => {
  const { teams, loading } = useFootballBase();
  const {
    selectedTeam,
    players,
    scoringPlayers,
    loadingPlayers,
    updatingScores,
    handleTeamChange,
    toggleScoringPlayer,
    updateScores,
    markMatchComplete
  } = useScoreManagement();

  if (loading) return <LoadingSpinner />;

  return (
    <Card>
      <div className="space-y-6">
        <div>
          <h2 className="text-lg font-semibold mb-4">Update Scores</h2>
          
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Select Team
              </label>
              <select
                value={selectedTeam}
                onChange={(e) => handleTeamChange(e.target.value)}
                className="w-full p-2 border rounded-lg"
                disabled={updatingScores}
              >
                <option value="">Select a team</option>
                {teams.map((team) => (
                  <option key={team._id} value={team._id}>
                    {team.name}
                  </option>
                ))}
              </select>
            </div>

            {loadingPlayers ? (
              <div className="py-8 flex justify-center">
                <LoadingSpinner />
              </div>
            ) : selectedTeam && players.length > 0 ? (
              <div className="space-y-4">
                <h3 className="font-medium">Select Scoring Players</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                  {players.map((player) => (
                    <div
                      key={player._id}
                      className={`flex items-center justify-between p-3 rounded-lg border
                        transition-colors cursor-pointer
                        ${scoringPlayers.includes(player._id)
                          ? 'bg-green-50 border-green-200'
                          : 'hover:bg-gray-50 border-gray-200'
                        }`}
                      onClick={() => toggleScoringPlayer(player._id)}
                    >
                      <span className="font-medium">{player.name}</span>
                      {scoringPlayers.includes(player._id) && (
                        <Check className="w-5 h-5 text-green-600" />
                      )}
                    </div>
                  ))}
                </div>

                <div className="flex gap-4 pt-4">
                  <button
                    onClick={updateScores}
                    disabled={updatingScores}
                    className="px-4 py-2 bg-green-600 text-white rounded-lg 
                      hover:bg-green-700 transition-colors disabled:bg-green-300
                      flex items-center gap-2"
                  >
                    {updatingScores && <Loader2 className="w-4 h-4 animate-spin" />}
                    Update Scores
                  </button>
                  
                  <button
                    onClick={() => markMatchComplete(selectedTeam)}
                    disabled={updatingScores}
                    className="px-4 py-2 bg-blue-600 text-white rounded-lg 
                      hover:bg-blue-700 transition-colors disabled:bg-blue-300"
                  >
                    Mark Match Complete
                  </button>
                </div>
              </div>
            ) : selectedTeam ? (
              <div className="py-8 text-center text-gray-500">
                No players found for this team
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default ScoreManagement;