import React from 'react';
import { AlertCircle, XCircle, Info, Trophy } from 'lucide-react';

export const StateMessage = ({ 
  title,
  message,
  type = 'info',
  icon,
  action = null,
  className = ''
}) => {
  const getTypeStyles = () => {
    switch (type) {
      case 'error':
        return {
          container: 'bg-red-50 border-red-100',
          icon: <XCircle className="w-5 h-5 text-red-600" />,
          title: 'text-red-800',
          message: 'text-red-600'
        };
      case 'warning':
        return {
          container: 'bg-yellow-50 border-yellow-100',
          icon: <AlertCircle className="w-5 h-5 text-yellow-600" />,
          title: 'text-yellow-800',
          message: 'text-yellow-600'
        };
      case 'success':
        return {
          container: 'bg-orange-50 border-orange-200',
          icon: <Trophy className="w-5 h-5 text-orange-600" />,
          title: 'text-orange-800',
          message: 'text-orange-600'
        };
      case 'empty':
        return {
          container: 'bg-gray-50 border-gray-100',
          icon: <Info className="w-5 h-5 text-gray-400" />,
          title: 'text-gray-800',
          message: 'text-gray-600'
        };
      default:
        return {
          container: 'bg-primary-50 border-primary-100',
          icon: <Info className="w-5 h-5 text-primary-600" />,
          title: 'text-primary-800',
          message: 'text-primary-600'
        };
    }
  };

  const styles = getTypeStyles();

  return (
    <div className={`rounded-lg border p-4 ${styles.container} ${className}`}>
      <div className="flex">
        <div className="flex-shrink-0">
          {icon || styles.icon}
        </div>
        <div className="ml-3">
          {title && (
            <h3 className={`text-sm font-medium ${styles.title}`}>
              {title}
            </h3>
          )}
          <div className={`text-sm ${styles.message} ${title ? 'mt-2' : ''}`}>
            {message}
          </div>
          {action && (
            <div className="mt-4">
              {action}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};