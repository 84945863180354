import React from 'react';
import { ChevronDown, Loader2 } from 'lucide-react';

const SelectionRow = ({
  index,
  selection,
  teams,
  players,
  onTeamChange,
  onPlayerChange,
  disabled,
  isLoading
}) => {
  const availablePlayers = players[selection.team] || [];
  
  return (
    <div className="grid grid-cols-12 gap-4 items-center bg-gray-50 p-4 rounded-lg transition-all duration-300 ease-in-out hover:bg-gray-100">
      <span className="col-span-1 font-bold text-lg text-gray-400">
        {index + 1}.
      </span>
      
      <div className="col-span-11 sm:col-span-5 relative">
        <select
          value={selection.teamId || ''}
          onChange={(e) => onTeamChange(index, e.target.value)}
          className={`w-full p-3 pr-10 border rounded-lg appearance-none bg-white cursor-pointer 
            hover:border-emerald-500 focus:ring-emerald-500/20 focus:border-emerald-500
            transition-all disabled:opacity-50 disabled:cursor-not-allowed`}
          disabled={disabled}
        >
          <option value="">Select Team</option>
          {teams.map((team) => (
            <option key={team._id} value={team._id}>
              {team.name}
            </option>
          ))}
        </select>
        <ChevronDown 
          className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none" 
          size={20} 
        />
      </div>

      <div className="col-span-11 col-start-2 sm:col-span-6 sm:col-start-7 relative">
        {isLoading ? (
          <div className="w-full p-3 border rounded-lg bg-gray-50">
            <Loader2 className="w-5 h-5 animate-spin text-gray-400 mx-auto" />
          </div>
        ) : (
          <>
            <select
              value={selection.playerId || ''}
              onChange={(e) => onPlayerChange(index, e.target.value)}
              className={`w-full p-3 pr-10 border rounded-lg appearance-none bg-white cursor-pointer
                hover:border-emerald-500 focus:ring-emerald-500/20 focus:border-emerald-500
                transition-all disabled:opacity-50 disabled:cursor-not-allowed`}
              disabled={disabled || !selection.team}
            >
              <option value="">Select Player</option>
              {availablePlayers.map((player) => (
                <option key={player._id} value={player._id}>
                  {player.name}
                </option>
              ))}
            </select>
            <ChevronDown 
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none" 
              size={20} 
            />
          </>
        )}
      </div>
    </div>
  );
};

export default SelectionRow;