import React, { createContext, useContext } from 'react';

const FeatureFlagsContext = createContext();

export const FeatureFlagsProvider = ({ children }) => {
  const flags = {
    SHOW_FOOTBALL: process.env.REACT_APP_SHOW_FOOTBALL === 'true',
    SHOW_AMERICAN_FOOTBALL: process.env.REACT_APP_SHOW_AMERICAN_FOOTBALL === 'true',  // changed
    SHOW_NFLSC: process.env.REACT_APP_SHOW_NFLSC === 'true',                         // changed
    SHOW_GOLF: process.env.REACT_APP_SHOW_GOLF === 'true',
    SHOW_RUGBY: process.env.REACT_APP_SHOW_RUGBY === 'true',
    isMainSite: process.env.REACT_APP_SITE_TYPE === 'main',
    siteType: process.env.REACT_APP_SITE_TYPE
  };

  return (
    <FeatureFlagsContext.Provider value={flags}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export const useFeatureFlags = () => {
  const context = useContext(FeatureFlagsContext);
  if (!context) {
    throw new Error('useFeatureFlags must be used within a FeatureFlagsProvider');
  }
  return context;
};