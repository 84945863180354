import React from 'react';
import { Users, Clock } from 'lucide-react';

const AllSelections = ({ matches, selections = [], isDeadlinePassed, currentUserId: currentUserName }) => {
    // Add debug logging
    console.log('Rugby AllSelections received:', {
        matches,
        selections,
        isDeadlinePassed,
        currentUserName
    });

    // Add type checking
    if (!Array.isArray(selections)) {
        console.error('Selections is not an array:', selections);
        selections = []; // Convert to empty array if not an array
    }

    if (!isDeadlinePassed) {
        return (
            <div className="bg-gradient-to-r from-primary-50 to-white rounded-lg shadow-sm p-6">
                <div className="flex items-center justify-center gap-3 text-orange-600">
                    <Clock className="w-5 h-5" />
                    <p className="text-lg font-medium">
                        Selections will be visible after the deadline
                    </p>
                </div>
            </div>
        );
    }

    return (
        <div className="space-y-4">
            {matches.map((match) => {
                const matchSelections = selections?.map(selection => {
                    const userName = selection.user.displayName;
                    const selectionId = selection._id;
                    
                    const prediction = selection.selections.find(s => 
                        s.homeTeam === match.homeTeam && 
                        s.awayTeam === match.awayTeam
                    );
                    
                    if (!prediction) return null;

                    const isCurrentUser = userName === currentUserName;

                    console.log('Selection mapping:', {
                        userName,
                        selectionId,
                        isCurrentUser,
                        currentUserName
                    });

                    return {
                        userId: selectionId,
                        userName,
                        prediction,
                        isCurrentUser
                    };
                }).filter(Boolean) || [];

                // Sort selections to put current user first
                const sortedSelections = [...matchSelections].sort((a, b) => {
                    if (a.isCurrentUser) return -1;
                    if (b.isCurrentUser) return 1;
                    return a.userName.localeCompare(b.userName);
                });

                return (
                    <div key={match._id} className="bg-gradient-to-r from-primary-50 to-white rounded-lg shadow-sm overflow-hidden">
                        {sortedSelections.length > 0 ? (
                            <div>
                                <div className="grid grid-cols-3">
                                    {/* Column Headers */}
                                    <div className="text-sm text-primary-500 px-4 py-3 bg-primary-50/80 border-b border-primary-100">
                                        Player
                                    </div>
                                    <div className="text-center font-medium py-3 bg-primary-50/80 border-b border-primary-100 text-primary-600">
                                        {match.homeTeam}
                                    </div>
                                    <div className="text-center font-medium py-3 bg-primary-50/80 border-b border-primary-100 text-primary-600">
                                        {match.awayTeam}
                                    </div>

                                    {/* Predictions */}
                                    {sortedSelections.map((selection, index) => (
                                        <React.Fragment key={selection.userId}>
                                            <div className={`px-4 py-3 flex items-center ${
                                                selection.isCurrentUser
                                                    ? 'bg-orange-50 border-l-4 border-orange-500' 
                                                    : index % 2 === 0 
                                                        ? 'bg-primary-50/30' 
                                                        : 'bg-white'
                                            }`}>
                                                <div>
                                                    <span className={`${selection.isCurrentUser ? 'font-medium' : ''} text-primary-600`}>
                                                        {selection.userName}
                                                        {selection.isCurrentUser && ' (You)'}
                                                    </span>
                                                    {match.result?.isComplete && (
                                                        <span className="ml-2 text-sm text-orange-500 font-medium">
                                                            {selection.prediction.points} pts
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                            <div className={`text-center py-3 font-bold text-primary-600 ${
                                                selection.isCurrentUser
                                                    ? 'bg-orange-50'
                                                    : index % 2 === 0 ? 'bg-primary-50/30' : 'bg-white'
                                            }`}>
                                                {selection.prediction.predictedHomeScore}
                                            </div>
                                            <div className={`text-center py-3 font-bold text-primary-600 ${
                                                selection.isCurrentUser
                                                    ? 'bg-orange-50'
                                                    : index % 2 === 0 ? 'bg-primary-50/30' : 'bg-white'
                                            }`}>
                                                {selection.prediction.predictedAwayScore}
                                            </div>
                                        </React.Fragment>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <div className="p-6 text-center">
                                <Users className="w-6 h-6 mx-auto mb-2 text-primary-400" />
                                <p className="text-primary-500">No predictions made for this match</p>
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default AllSelections;