import { golfService } from './services';

export const getPublicGameInfo = () => golfService.getPublicGameInfo();

export const getCurrentGame = () => golfService.getCurrentGame();
export const getTournamentLeaderboard = () => golfService.getTournamentLeaderboard();
export const getPlayers = () => golfService.getPlayers();
export const submitSelections = (selections) => golfService.submitSelections(selections);
export const getMySelections = () => golfService.getMySelections();
export const getGameLeaderboard = () => golfService.getGameLeaderboard();
export const joinLeague = (code) => golfService.joinLeague(code);
export const getLeagueStandings = (leagueId) => golfService.getLeagueStandings(leagueId);

// Export the service itself in case it's needed
export const service = golfService;