import { useState, useEffect } from 'react';
import { rugbyAdminService } from '../../api/services';
import useRugbyBase from './useRugbyBase';

export const useScoreManagement = () => {
  const { loading, error, syncMessage, setError, setSyncMessage, handleError } = useRugbyBase();
  const [matches, setMatches] = useState([]);

  useEffect(() => {
    fetchMatches();
  }, []);

  const fetchMatches = async () => {
    try {
      console.log('Fetching matches...');
      const response = await rugbyAdminService.getGameweekResults();
      console.log('Matches response:', response);
      if (response.data) {
        setMatches(response.data);
      } else {
        setMatches(response || []);
      }
      console.log('Matches set:', matches);
    } catch (err) {
      console.error('Error fetching matches:', err);
      handleError(err, 'Failed to fetch matches');
    }
  };

  const updateMatchScore = async (matchId, homeScore, awayScore) => {
    try {
      console.log('Updating score:', { matchId, homeScore, awayScore });
      setSyncMessage('Updating match score...');
      await rugbyAdminService.updateMatchScore(matchId, homeScore, awayScore);
      await fetchMatches();
      setSyncMessage('Score updated successfully!');
    } catch (err) {
      console.error('Error updating score:', err);
      handleError(err, 'Failed to update match score');
    }
  };

  return {
    matches,
    loading,
    error,
    syncMessage,
    updateMatchScore,
    refreshMatches: fetchMatches
  };
};

export default useScoreManagement;