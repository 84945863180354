import React, { useState } from 'react';
import Card from '../shared/Card';
import { LoadingSpinner, EmptyState } from '../../../components/ui/state';
import useScoreManagement from '../../../hooks/rugby/useScoreManagement';
import { Trophy } from 'lucide-react';

const ScoreManagement = () => {
  const {
    matches,
    loading,
    error,
    syncMessage,
    updateMatchScore,
    refreshMatches
  } = useScoreManagement();

  const [scores, setScores] = useState({});

  const handleScoreUpdate = async (matchId) => {
    const matchScores = scores[matchId] || {};
    if (!matchScores.homeScore || !matchScores.awayScore) return;

    await updateMatchScore(
      matchId,
      parseInt(matchScores.homeScore),
      parseInt(matchScores.awayScore)
    );
  };

  if (loading) {
    return (
      <div className="flex justify-center p-8">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {error && (
        <div className="bg-red-50 border border-red-200 text-red-700 p-4 rounded-lg">
          {error}
        </div>
      )}

      {syncMessage && (
        <div className="bg-green-50 border border-green-200 text-green-700 p-4 rounded-lg">
          {syncMessage}
        </div>
      )}

      <Card title="Match Scores">
        {!matches || matches.length === 0 ? (
          <EmptyState
            title="No Matches Found"
            message="There are no matches available for the current gameweek"
            icon={<Trophy className="w-12 h-12" />}
            action={
              <button
                onClick={refreshMatches}
                className="text-blue-600 hover:text-blue-700 font-medium"
              >
                Refresh Matches
              </button>
            }
          />
        ) : (
          <div className="space-y-4">
            {matches.map((match) => (
              <div key={match._id} className="p-4 bg-gray-50 rounded-lg">
                <div className="flex items-center justify-between mb-4">
                  <div className="grid grid-cols-5 gap-4 items-center w-full">
                    <div className="col-span-2 text-right font-medium">
                      {match.homeTeam}
                    </div>
                    <div className="text-center text-sm text-gray-500">vs</div>
                    <div className="col-span-2 font-medium">
                      {match.awayTeam}
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-5 gap-4 items-center">
                  <div className="col-span-2">
                    <input
                      type="number"
                      min="0"
                      placeholder="Home Score"
                      value={scores[match._id]?.homeScore || ''}
                      onChange={(e) => setScores({
                        ...scores,
                        [match._id]: { ...scores[match._id], homeScore: e.target.value }
                      })}
                      className="w-full p-2 border rounded-lg text-center"
                    />
                  </div>
                  <div className="text-center">-</div>
                  <div className="col-span-2">
                    <input
                      type="number"
                      min="0"
                      placeholder="Away Score"
                      value={scores[match._id]?.awayScore || ''}
                      onChange={(e) => setScores({
                        ...scores,
                        [match._id]: { ...scores[match._id], awayScore: e.target.value }
                      })}
                      className="w-full p-2 border rounded-lg text-center"
                    />
                  </div>
                </div>

                <div className="mt-4 flex justify-between items-center">
                  {match.result?.isComplete ? (
                    <div className="text-green-600 flex items-center">
                      Current Score: {match.result.homeScore} - {match.result.awayScore}
                    </div>
                  ) : (
                    <div className="text-gray-500">No score recorded</div>
                  )}
                  <button
                    onClick={() => handleScoreUpdate(match._id)}
                    disabled={!scores[match._id]?.homeScore || !scores[match._id]?.awayScore}
                    className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 
                      disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
                  >
                    Update Score
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </Card>
    </div>
  );
};

export default ScoreManagement;