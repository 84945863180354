import { useState, useEffect } from 'react';

export const useCountdown = (deadline) => {
  const [timeLeft, setTimeLeft] = useState(() => calculateTimeLeft(deadline));

  function calculateTimeLeft(deadline) {
    if (!deadline) {
      console.log("No deadline provided");
      return null;
    }

    let deadlineDate;
    try {
      deadlineDate = new Date(deadline);
      console.log("Parsed deadline date:", deadlineDate);
      if (deadlineDate.toString() === 'Invalid Date') {
        console.log("Failed to parse deadline:", deadline);
        return null;
      }
    } catch (error) {
      console.error("Error parsing date:", error);
      return null;
    }

    const now = new Date();
    const difference = deadlineDate.getTime() - now.getTime();
    
    if (difference <= 0) {
      return null;
    }

    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60)
    };
  }

  useEffect(() => {
    if (!deadline) return;

    setTimeLeft(calculateTimeLeft(deadline));

    const timer = setInterval(() => {
      const newTimeLeft = calculateTimeLeft(deadline);
      if (newTimeLeft === null) {
        clearInterval(timer);
      }
      setTimeLeft(newTimeLeft);
    }, 60000);

    return () => clearInterval(timer);
  }, [deadline]);

  return timeLeft;
};