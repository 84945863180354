import React, { useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { Input, Button, FormMessage } from '../components/ui/form';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login, error: authError, loading } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(email, password);
      const from = location.state?.from?.pathname || '/';
      navigate(from, { replace: true });
    } catch (err) {
      console.error('Login error:', err);
    }
  };

  return (
    <div className="max-w-md mx-auto mt-10 bg-white p-8 border border-gray-300 rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-6 text-center">Login</h2>
      
      {authError && (
        <FormMessage type="error" className="mb-4">
          {authError}
        </FormMessage>
      )}

      <form onSubmit={handleSubmit} className="space-y-4">
        <Input
          type="email"
          id="email"
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          disabled={loading}
        />

        <Input
          type="password"
          id="password"
          label="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          disabled={loading}
        />

        <Button
          type="submit"
          isLoading={loading}
          className="w-full"
        >
          Login
        </Button>

        <div className="text-center">
          <Link 
            to="/forgot-password" 
            className="text-purple-600 hover:text-purple-700 text-sm"
          >
            Forgot Password?
          </Link>
        </div>
      </form>

      <div className="mt-6 text-center text-sm text-gray-600">
        Don't have an account?{' '}
        <Link 
          to="/register" 
          className="text-purple-600 hover:text-purple-700"
        >
          Register here
        </Link>
      </div>
    </div>
  );
}

export default Login;