import React from 'react';
import { cn } from '../../../utils/cn';

const Input = React.forwardRef(({ 
  className,
  type = 'text',
  error,
  label,
  id,
  ...props
}, ref) => {
  return (
    <div className="space-y-1">
      {label && (
        <label 
          htmlFor={id} 
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
      )}
      <input
        type={type}
        id={id}
        ref={ref}
        className={cn(
          "w-full px-3 py-2 border rounded-md transition-colors",
          "focus:outline-none focus:ring-2 focus:ring-purple-500",
          error 
            ? "border-red-300 text-red-900 placeholder-red-300"
            : "border-gray-300 text-gray-900",
          className
        )}
        {...props}
      />
      {error && (
        <p className="text-sm text-red-600">{error}</p>
      )}
    </div>
  );
});

Input.displayName = 'Input';

export default Input;