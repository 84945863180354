import React, { useState } from 'react';
import { AlertCircle } from 'lucide-react';

const MatchCard = ({ 
  match, 
  index,
  prediction,
  onHomeScoreChange,
  onAwayScoreChange,
  disabled,
  error
}) => (
  <div className="p-4 bg-gradient-to-r from-primary-50 to-white rounded-lg border border-primary-100 hover:shadow-md transition-all">
    <div className="flex justify-center items-start gap-8 max-w-full">
      <div className="flex flex-col items-center">
        <label 
          htmlFor={`home-score-${index}`}
          className="font-medium text-primary-600 mb-2"
        >
          {match.homeTeam}
        </label>
        <input
          id={`home-score-${index}`}
          type="number"
          min="0"
          max="999"
          value={prediction.homeScore}
          onChange={(e) => onHomeScoreChange(e.target.value)}
          className="w-16 h-10 border rounded-lg text-center 
            focus:ring-2 focus:ring-orange-500/20 focus:border-orange-500
            disabled:bg-primary-50 disabled:text-primary-400
            [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none
            placeholder-primary-300"
          disabled={disabled}
          aria-label={`Score for ${match.homeTeam}`}
        />
      </div>

      <div className="flex flex-col items-center">
        <span className="font-medium text-primary-400 mb-2">vs</span>
        <div className="h-10"></div>
      </div>

      <div className="flex flex-col items-center">
        <label 
          htmlFor={`away-score-${index}`}
          className="font-medium text-primary-600 mb-2"
        >
          {match.awayTeam}
        </label>
        <input
          id={`away-score-${index}`}
          type="number"
          min="0"
          max="999"
          value={prediction.awayScore}
          onChange={(e) => onAwayScoreChange(e.target.value)}
          className="w-16 h-10 border rounded-lg text-center
            focus:ring-2 focus:ring-orange-500/20 focus:border-orange-500
            disabled:bg-primary-50 disabled:text-primary-400
            [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none
            placeholder-primary-300"
          disabled={disabled}
          aria-label={`Score for ${match.awayTeam}`}
        />
      </div>
    </div>
    {error && (
      <div className="mt-2 text-center text-sm text-red-500 flex items-center justify-center">
        <AlertCircle className="w-4 h-4 mr-1" />
        {error}
      </div>
    )}
  </div>
);

// View mode template
const MatchViewCard = ({ match, selection }) => (
  <div className="p-4 bg-gradient-to-r from-primary-50 to-white rounded-lg border border-primary-100">
    <div className="flex justify-center items-start gap-8">
      <div className="flex flex-col items-center">
        <div className="font-medium text-primary-600 mb-2">{match.homeTeam}</div>
        <div className="text-2xl font-bold text-primary-600">
          {selection?.predictedHomeScore}
        </div>
      </div>

      <div className="flex flex-col items-center">
        <span className="font-medium text-primary-400 mb-2">vs</span>
      </div>

      <div className="flex flex-col items-center">
        <div className="font-medium text-primary-600 mb-2">{match.awayTeam}</div>
        <div className="text-2xl font-bold text-primary-600">
          {selection?.predictedAwayScore}
        </div>
      </div>
    </div>
    {match.result?.isComplete && (
      <div className="mt-3 text-center">
        <span className="text-sm text-orange-500 font-medium">
          {selection?.points} points
        </span>
      </div>
    )}
  </div>
);

const MatchPrediction = ({ 
  matches, 
  onSubmit, 
  initialSelections = [], 
  isEditing = false,
  disabled = false 
}) => {
  const [predictions, setPredictions] = useState(
    matches.map(match => {
      const initialSelection = initialSelections.find(s => s.match === match._id || s.matchId === match._id);
      return {
        matchId: match._id,
        homeScore: initialSelection?.predictedHomeScore ?? '',
        awayScore: initialSelection?.predictedAwayScore ?? '',
        error: null
      };
    })
  );

  // View mode
  if (!isEditing && initialSelections.length > 0) {
    return (
      <div className="space-y-4 overflow-x-hidden w-full">
        {matches.map((match) => {
          const selection = initialSelections.find(s => s.match === match._id);
          return (
            <MatchViewCard 
              key={match._id}
              match={match}
              selection={selection}
            />
          );
        })}
      </div>
    );
  }

  const handleScoreChange = (index, team, value) => {
    const newPredictions = [...predictions];
    const numValue = value === '' ? '' : parseInt(value, 10);
    newPredictions[index] = {
      ...newPredictions[index],
      [team]: value,
      error: null
    };
    setPredictions(newPredictions);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formattedPredictions = predictions.map(p => ({
      ...p,
      homeScore: parseInt(p.homeScore, 10),
      awayScore: parseInt(p.awayScore, 10)
    }));

    if (formattedPredictions.every(p => 
      !isNaN(p.homeScore) && !isNaN(p.awayScore) && 
      p.homeScore >= 0 && p.awayScore >= 0
    )) {
      onSubmit(formattedPredictions);
    }
  };

  const isValid = predictions.every(p => 
    p.homeScore !== '' && p.awayScore !== '' &&
    !p.error && 
    parseInt(p.homeScore) >= 0 && parseInt(p.awayScore) >= 0
  );

  return (
    <div className="overflow-x-hidden w-full">
      <form onSubmit={handleSubmit} className="space-y-4">
        {matches.map((match, index) => (
          <MatchCard
            key={match._id}
            match={match}
            index={index}
            prediction={predictions[index]}
            onHomeScoreChange={(value) => handleScoreChange(index, 'homeScore', value)}
            onAwayScoreChange={(value) => handleScoreChange(index, 'awayScore', value)}
            disabled={disabled}
            error={predictions[index].error}
          />
        ))}
  
        {!disabled && (
          <button
          type="submit"
          disabled={!isValid}
          className="w-full bg-orange-500 text-white px-6 py-3 rounded-lg 
            font-medium focus:outline-none focus:ring-2 focus:ring-orange-500/20 
            transition-all duration-300 hover:bg-orange-600
            disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {initialSelections.length > 0 ? 'Update Predictions' : 'Submit Predictions'}
        </button>
        )}
      </form>
    </div>
  );
};

export default MatchPrediction;