import React from 'react';
import { Clock } from 'lucide-react';
import Card from '../shared/Card';
import useDataSync from '../../../hooks/rugby/useDataSync';
import { LoadingSpinner } from '../../../components/ui/state';

const DataSync = () => {
  const {
    lastSyncTime,
    loading,
    error,
    syncMessage,
    syncGameweekData
  } = useDataSync();

  if (loading) return <LoadingSpinner />;

  return (
    <div className="space-y-6">
      {error && (
        <div className="bg-red-50 border border-red-200 text-red-700 p-4 rounded-lg">
          {error}
        </div>
      )}

      {syncMessage && (
        <div className="bg-green-50 border border-green-200 text-green-700 p-4 rounded-lg">
          {syncMessage}
        </div>
      )}

      <Card>
        <div className="flex justify-between items-center">
          <div>
            <h2 className="text-lg font-semibold">Match Data Sync</h2>
            <p className="text-sm text-gray-500 mt-1">
              Sync matches from Google Sheets
            </p>
          </div>
          <div className="flex items-center gap-4">
            {lastSyncTime && (
              <div className="flex items-center gap-2 text-sm text-gray-500">
                <Clock className="w-4 h-4" />
                Last sync: {new Date(lastSyncTime).toLocaleString()}
              </div>
            )}
            <button
              onClick={syncGameweekData}
              className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
            >
              Sync Match Data
            </button>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default DataSync;
