import { useState, useCallback } from 'react';
import { footballAdminService } from '../../api/services/admin/footballAdminService';
import useFootballBase from './useFootballBase';

const usePopularPlayers = () => {
  const { showMessage } = useFootballBase();
  const [selectedTeam, setSelectedTeam] = useState('');
  const [players, setPlayers] = useState([]);
  const [loadingPlayers, setLoadingPlayers] = useState(false);
  const [updatingPlayer, setUpdatingPlayer] = useState(null);

  const fetchTeamPlayers = useCallback(async (teamId) => {
    try {
        setLoadingPlayers(true);
        console.log('Fetching players for team:', teamId);
        const players = await footballAdminService.getAllTeamPlayers(teamId);
        console.log('Players received:', players);
        
        // players should now be the array directly
        setPlayers(Array.isArray(players) ? players : []);
    } catch (error) {
        console.error('Error fetching players:', error);
        showMessage(error.message, true);
        setPlayers([]);
    } finally {
        setLoadingPlayers(false);
    }
}, [showMessage]);

  const handleTeamChange = async (teamId) => {
    setSelectedTeam(teamId);
    if (teamId) {
      await fetchTeamPlayers(teamId);
    } else {
      setPlayers([]);
    }
  };

  const togglePopularPlayer = async (playerId) => {
    try {
      setUpdatingPlayer(playerId);
      const response = await footballAdminService.togglePopularPlayer(playerId);
      
      // Update local state to reflect the change
      setPlayers(prev => prev.map(player => {
        if (player._id === playerId) {
          return {
            ...player,
            isPopularPick: !player.isPopularPick
          };
        }
        return player;
      }));
      
      showMessage('Player popular status updated successfully!');
    } catch (error) {
      showMessage(error.message, true);
    } finally {
      setUpdatingPlayer(null);
    }
  };

  return {
    selectedTeam,
    players,
    loadingPlayers,
    updatingPlayer,
    handleTeamChange,
    togglePopularPlayer
  };
};

export default usePopularPlayers;