import React from 'react';
import { RefreshCcw } from 'lucide-react';

const LeaderboardCard = ({ title, icon: Icon, onRefresh, children }) => (
  <div className="bg-white rounded-lg shadow-sm">
    {/* Header section */}
    <div className="px-4 py-3 flex justify-between items-center border-b">
      <div className="flex items-center gap-2">
        {Icon && <Icon className="w-5 h-5 text-blue-500" />}
        <h2 className="text-lg font-bold">{title}</h2>
      </div>
      {onRefresh && (
        <button
          onClick={onRefresh}
          className="p-1.5 text-gray-500 hover:text-gray-700 transition-colors"
        >
          <RefreshCcw className="w-4 h-4" />
        </button>
      )}
    </div>
    {/* Content section */}
    {children}
  </div>
);

export default LeaderboardCard;