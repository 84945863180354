import React from 'react';
import ErrorMessage from '../ui/state/ErrorMessage';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Caught an error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorMessage message={this.state.error.message} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;