import React, { useState, useEffect } from 'react';
import { Calendar, Users, Clock, Trophy } from 'lucide-react';
import { useAuth } from '../../AuthContext';
import { LoadingSpinner, StateMessage } from '../../components/ui/state';
import { 
  fetchCurrentGameFixtures, 
  fetchUserSelections, 
  fetchAllSelections, 
  fetchTeams, 
  fetchPlayers, 
  submitSelections, 
  updateUserSelections 
} from '../../api/football';
import { footballService } from '../../api/services/game/footballService';  

// Import our new components
import PlayerSelection from '../../components/football/PlayerSelection';
import FootballAllSelections from '../../components/football/FootballAllSelections';
import FixtureList from '../../components/football/FixtureList';

const useCountdown = (deadline) => {
  const [timeLeft, setTimeLeft] = useState(() => calculateTimeLeft(deadline));

  function calculateTimeLeft(deadline) {
    if (!deadline) return null;
    const difference = new Date(deadline) - new Date();
    if (difference <= 0) return null;
    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60)
    };
  }

  useEffect(() => {
    if (!deadline) return;
    setTimeLeft(calculateTimeLeft(deadline));
    const timer = setInterval(() => {
      const newTimeLeft = calculateTimeLeft(deadline);
      if (newTimeLeft === null) clearInterval(timer);
      setTimeLeft(newTimeLeft);
    }, 60000);
    return () => clearInterval(timer);
  }, [deadline]);

  return timeLeft;
};

const Football5sPage = () => {
  const [currentGame, setCurrentGame] = useState(null);
  const [fixtures, setFixtures] = useState([]);
  const [userSelections, setUserSelections] = useState(null);
  const [allSelections, setAllSelections] = useState([]);
  const [teams, setTeams] = useState([]);
  const [players, setPlayers] = useState({});
  const [popularPlayers, setPopularPlayers] = useState([]);
  const [selections, setSelections] = useState(Array(5).fill({ 
    teamId: '',
    team: '',
    playerId: '',
    player: '' 
  }));
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const { user } = useAuth();
  const [loadingTeam, setLoadingTeam] = useState(null);
  const timeLeft = useCountdown(currentGame?.deadline);
  const [selectionsCount, setSelectionsCount] = useState(0);
  const [isDeadlinePassed, setIsDeadlinePassed] = useState(false);

  // Load initial data
  useEffect(() => {
    const loadData = async () => {
      try {
        const gameData = await fetchCurrentGameFixtures();
        setCurrentGame(gameData.currentGame);
        setFixtures(gameData.matches || []);
      
        if (gameData.currentGame) {
          const deadline = new Date(gameData.currentGame.deadline);
          const now = new Date();
          setIsDeadlinePassed(now > deadline);
          
          if (user) {  
            const allSelectionsResponse = await fetchAllSelections();
            console.log('All selections loaded:', allSelectionsResponse);
            setAllSelections(allSelectionsResponse.selections || []);
            setSelectionsCount(allSelectionsResponse.selections?.length || 0);
          }
        }
  
        if (user && gameData.currentGame) {
          try {
            const [teamsData, userSelectionsData, popularPlayersData] = await Promise.all([
              fetchTeams(),
              fetchUserSelections(),
              footballService.fetchPopularPlayers()
            ]);
        
            console.log('Raw popular players response:', popularPlayersData);
            setTeams(teamsData);
            setPopularPlayers(popularPlayersData || []);
console.log('Just set popularPlayers state to:', popularPlayersData);
        
            if (userSelectionsData && userSelectionsData.length > 0) {
              setUserSelections(userSelectionsData[0]);
              setSelections(userSelectionsData[0].selections);
            }
          } catch (error) {
            console.error('Error loading data:', error);
            setError('Failed to load data');
          }
        }
      } catch (err) {
        console.error('Error in loadData:', err);
        if (err.message !== 'No active game found') {
          setError('Failed to load game data. Please try again later.');
        }
      } finally {
        setIsLoading(false);
      }
    };
  
    loadData();
  }, [user]);

useEffect(() => {
  if (currentGame && user) {
    console.log('Setting up selection refresh interval');
    
    const refreshSelections = async () => {
      try {
        const response = await fetchAllSelections();
        console.log('Refreshed selections:', response);
        setAllSelections(response.selections || []);
        setSelectionsCount(response.selections?.length || 0);
      } catch (error) {
        console.error('Error refreshing selections:', error);
      }
    };

    // Refresh every minute
    const interval = setInterval(refreshSelections, 300000);

    // Initial fetch
    refreshSelections();

    return () => {
      console.log('Clearing selection refresh interval');
      clearInterval(interval);
    };
  }
}, [currentGame, user]);

const handleTeamChange = async (index, teamId, quickPickPlayerId = null) => {
  try {
      setLoadingTeam(index);
      const selectedTeam = teams.find(t => t._id === teamId);
      if (!selectedTeam) return;

      const newSelections = [...selections];
      const currentSelection = newSelections[index];
      
      newSelections[index] = { 
          teamId: selectedTeam._id,
          team: selectedTeam.name,
          playerId: currentSelection.team === selectedTeam.name ? currentSelection.playerId : '',
          player: currentSelection.team === selectedTeam.name ? currentSelection.player : ''
      };
      setSelections(newSelections);

      const teamPlayers = await fetchPlayers(teamId);
      
      // If this is a quick pick, update both team and player at once
      if (quickPickPlayerId) {
          const selectedPlayer = teamPlayers.find(p => p._id === quickPickPlayerId);
          if (selectedPlayer) {
              newSelections[index] = {
                  teamId: selectedTeam._id,
                  team: selectedTeam.name,
                  playerId: selectedPlayer._id,
                  player: selectedPlayer.name
              };
              setSelections(newSelections);
          }
      }

      setPlayers(prevPlayers => ({
          ...prevPlayers,
          [selectedTeam.name]: teamPlayers
      }));

  } catch (error) {
      console.error('Error in handleTeamChange:', error);
  } finally {
      setLoadingTeam(null);
  }
};

const handlePlayerChange = (index, playerId) => {
  const selectedTeam = selections[index].team;
  console.log('handlePlayerChange called with:', {
      index,
      playerId,
      selectedTeam,
      availablePlayers: players[selectedTeam],
      currentSelections: selections
  });

  const selectedPlayer = players[selectedTeam]?.find(p => p._id === playerId);
  console.log('Found selected player:', selectedPlayer);

  if (!selectedPlayer) {
      console.log('No player found with ID:', playerId);
      return;
  }

  const isDuplicate = selections.some((selection, i) => 
      i !== index && selection.playerId === playerId
  );

  if (isDuplicate) {
      setError('This player has already been selected. Please choose another player.');
      return;
  }

  const newSelections = [...selections];
  newSelections[index] = { 
      ...newSelections[index],
      playerId: selectedPlayer._id,
      player: selectedPlayer.name
  };
  setSelections(newSelections);
  setError(null);
};

const handleSubmit = async (e) => {
  e.preventDefault();
  
  if (selections.some(s => !s.teamId || !s.playerId)) {
      setError('Please make all 5 selections before submitting.');
      return;
  }
  if (!currentGame) {
      setError('No active game found. Please refresh the page and try again.');
      return;
  }
  try {
      let updatedSelections;
      if (userSelections && userSelections._id) {
          updatedSelections = await updateUserSelections(
              userSelections._id, 
              selections,
              currentGame.name
          );
          // After successful update, format the selections the same way as initial submission
          const formattedSelections = {
              _id: userSelections._id,
              selections: selections.map(s => ({
                  team: s.team,
                  player: s.player,
                  hasScored: false
              }))
          };
          setUserSelections(formattedSelections);
          setSuccessMessage('Selections updated successfully!');
      } else {
          updatedSelections = await submitSelections(
              selections,
              currentGame.name
          );
          setUserSelections(updatedSelections);
          setSuccessMessage('Selections submitted successfully!');
      }

      setIsEditing(false);
      setError(null);
  } catch (err) {
      console.error('Error submitting selections:', err);
      setError(`Failed to submit selections: ${err.message}`);
  }
};

  const handleEdit = async () => {
    setSuccessMessage(null);
    try {
        // First load players for all selected teams
        const promises = userSelections.selections.map(async (selection) => {
            const selectedTeam = teams.find(t => t.name === selection.team);
            if (selectedTeam) {
                const playersData = await fetchPlayers(selectedTeam._id);
                return {
                    team: selection.team,
                    teamId: selectedTeam._id,
                    players: playersData
                };
            }
            return null;
        });

        const results = await Promise.all(promises);
        
        // Update players state with all teams' players
        const newPlayers = { ...players };
        results.forEach(result => {
            if (result) {
                newPlayers[result.team] = result.players;
            }
        });
        setPlayers(newPlayers);

        // Now format selections with both IDs and display values
        const formattedSelections = userSelections.selections.map(selection => {
            const team = teams.find(t => t.name === selection.team);
            const teamPlayers = newPlayers[selection.team] || [];
            const player = teamPlayers.find(p => p.name === selection.player);
            
            return {
                teamId: team?._id || '',
                team: selection.team,
                playerId: player?._id || '',
                player: selection.player
            };
        });

        setSelections(formattedSelections);
        setIsEditing(true);
    } catch (error) {
        console.error('Error preparing edit mode:', error);
        setError('Failed to load player data for editing');
    }
};

  const renderHeader = () => (
    <div className="bg-emerald-600 px-4 py-3">
      <div className="flex justify-between items-start">
        <div>
          <div className="flex items-center gap-2">
            <h1 className="text-xl font-bold text-white">Football 5s</h1>
            <div className="flex items-center text-white">
              <Trophy className="w-4 h-4 mr-1" />
              <span className="font-medium">€825</span>
            </div>
          </div>
          <div className="flex items-center gap-2 text-sm text-white/90 mt-1">
            <span>{currentGame?.name}</span>
            <span className="text-white/60">•</span>
            <span className="flex items-center">
              <Calendar className="w-3.5 h-3.5 mr-1" />
              {new Date(currentGame?.deadline).toLocaleString(undefined, {
                day: 'numeric',
                month: 'short',
                hour: '2-digit',
                minute: '2-digit'
              })}
            </span>
            {timeLeft && (
              <>
                <span className="text-white/60">•</span>
                <span className="flex items-center text-white/90">
                  <Clock className="w-3.5 h-3.5 mr-1" />
                  {timeLeft.days > 1 ? (
                    `${timeLeft.days}d`
                  ) : (
                    `${timeLeft.days * 24 + timeLeft.hours}h ${timeLeft.minutes}m`
                  )}
                </span>
              </>
            )}
            <span className="text-white/60">•</span>
            <span className="flex items-center">
              <Users className="w-3.5 h-3.5 mr-1" />
              {selectionsCount} Players
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  const renderNonAuthContent = () => (
    <div className="space-y-6">
      <div className="bg-white rounded-lg shadow-sm">
        <div className="p-6">
          <div className="text-center">
            <h3 className="text-xl font-semibold mb-3">
              Welcome to Football 5s
            </h3>
            <p className="text-gray-600 mb-6 max-w-md mx-auto">
              Join us to predict goal scorers for {currentGame?.name} and compete with other players.
            </p>
            <div className="space-x-4">
              <a 
                href="/login" 
                className="inline-block px-6 py-2.5 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 transition-colors"
              >
                Log In
              </a>
              <a 
                href="/register" 
                className="inline-block px-6 py-2.5 border border-emerald-600 text-emerald-600 rounded-lg hover:bg-emerald-50 transition-colors"
              >
                Register
              </a>
            </div>
          </div>
        </div>
      </div>

      <FixtureList fixtures={fixtures} />
    </div>
  );

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      {renderHeader()}
      
      <main className="container mx-auto px-4 py-8">
        <div className="max-w-4xl mx-auto space-y-6">
          {error && (
            <StateMessage type="error" message={error} />
          )}
          
          {successMessage && (
            <StateMessage type="success" message={successMessage} />
          )}
  
          {!currentGame ? (
            <div className="bg-white rounded-lg shadow-sm p-6">
              <p className="text-lg text-gray-600">
                There is no active gameweek at the moment. Please check back later.
              </p>
            </div>
          ) : (
            <>
              {!user ? (
                renderNonAuthContent()
              ) : (
                <>
                  {!isDeadlinePassed ? (
                    <PlayerSelection
                      selections={selections}
                      onSubmit={handleSubmit}
                      teams={teams}
                      players={players}
                      isEditing={isEditing}
                      onEdit={handleEdit}
                      disabled={false}
                      onTeamChange={handleTeamChange}
                      onPlayerChange={handlePlayerChange}
                      userSelections={userSelections}
                      popularPlayers={popularPlayers}
                    />
                  ) : (
                    <FootballAllSelections
                      selections={allSelections}
                      currentUserName={user.displayName}
                    />
                  )}
                  
                  <FixtureList fixtures={fixtures} />
                </>
              )}
            </>
          )}
        </div>
      </main>
    </div>
  );
};

export default Football5sPage;