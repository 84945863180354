import React from 'react';
import { Plus, Users } from 'lucide-react';
import Card from '../shared/Card';
import useLeagueManagement from '../../../hooks/golf/useLeagueManagement';
import LoadingSpinner from '../../ui/state/LoadingSpinner';

const LeagueManagement = () => {
  const {
    leagues,
    loading,
    error,
    syncMessage,
    newLeagueName,
    setNewLeagueName,
    createLeague,
    loadLeagues
  } = useLeagueManagement();

  if (loading) return <LoadingSpinner />;

  return (
    <div className="space-y-6">
      {error && (
        <div className="bg-red-50 border border-red-200 text-red-700 p-4 rounded-lg">
          {error}
        </div>
      )}

      {syncMessage && (
        <div className="bg-green-50 border border-green-200 text-green-700 p-4 rounded-lg">
          {syncMessage}
        </div>
      )}

      <Card>
        <div className="flex justify-between items-center mb-6">
          <div>
            <h2 className="text-lg font-semibold">League Management</h2>
            <p className="text-sm text-gray-500 mt-1">
              Create and manage private leagues for the current tournament
            </p>
          </div>
        </div>

        <form onSubmit={createLeague} className="mb-6">
          <div className="flex gap-4">
            <input
              type="text"
              value={newLeagueName}
              onChange={(e) => setNewLeagueName(e.target.value)}
              placeholder="Enter league name"
              className="flex-1 p-2 border rounded-lg focus:ring-2 focus:ring-blue-500/20 focus:border-blue-500"
              required
            />
            <button
              type="submit"
              disabled={!newLeagueName.trim()}
              className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg 
                hover:bg-blue-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <Plus className="w-4 h-4" />
              Create League
            </button>
          </div>
        </form>

        <div className="border-t pt-6">
          <h3 className="font-medium mb-4">Current Leagues</h3>
          <div className="space-y-3">
            {leagues.map(league => (
              <div 
                key={league._id}
                className="flex justify-between items-center p-4 bg-gray-50 rounded-lg"
              >
                <div>
                  <h4 className="font-medium">{league.name}</h4>
                  <div className="flex items-center text-sm text-gray-500 mt-1">
                    <Users className="w-4 h-4 mr-1" />
                    {league.members.length} members
                  </div>
                </div>
              </div>
            ))}

            {leagues.length === 0 && (
              <div className="text-center py-8 text-gray-500">
                No leagues created for this tournament yet.
              </div>
            )}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default LeagueManagement;