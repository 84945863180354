import React from 'react';
import { Edit } from 'lucide-react';
import QuickPicks from '../QuickPicks';
import SelectionRow from '../SelectionRow';

const PlayerSelection = ({ 
    selections, 
    onSubmit,
    teams,
    players,
    isEditing = false,
    disabled = false,
    onTeamChange,
    onPlayerChange,
    userSelections,
    onEdit,
    popularPlayers = []
  }) => {
    console.log('PlayerSelection props:', {
      selections,
      userSelections,
      isEditing,
      disabled
    });
  
    if (!isEditing && userSelections) {
      return (
        <div className="bg-white rounded-lg shadow-sm p-6 transition-all duration-300 ease-in-out">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-bold">My Selections</h2>
            {!disabled && (
              <button
                onClick={onEdit}
                className="flex items-center px-4 py-2 text-sm font-medium text-emerald-600 border border-emerald-600 rounded-lg hover:bg-emerald-50 transition-colors duration-300"
              >
                <Edit className="w-4 h-4 mr-2" />
                Edit Selections
              </button>
            )}
          </div>
          <div className="space-y-3">
            {userSelections?.selections?.map((selection, index) => (
              <div
                key={index}
                className="flex items-center justify-between p-3 bg-gray-50 rounded-lg hover:bg-gray-100 transition-all duration-300 ease-in-out"
              >
                <div className="flex items-center">
                  <span className="font-bold text-lg text-gray-400 w-8">{index + 1}.</span>
                  <span className="font-medium">{selection.player}</span>
                </div>
                <span className="text-gray-500">({selection.team})</span>
              </div>
            ))}
          </div>
        </div>
      );
    }

  return (
    <div className="bg-white rounded-lg shadow-sm p-6 transition-all duration-300 ease-in-out">
      <h2 className="text-2xl font-bold mb-6">Make Selections</h2>
      
      {/* Only show QuickPicks if this is an initial submission (not editing) */}
      {!userSelections && popularPlayers.length > 0 && (
        <QuickPicks 
            players={popularPlayers}
            onPlayerSelect={(player) => {
                console.log('Quick pick selected:', player);
                const emptyIndex = selections.findIndex(s => !s.playerId);
                if (emptyIndex !== -1) {
                    onTeamChange(emptyIndex, player.teamId, player.playerId);
                }
            }}
            disabled={disabled}
        />
      )}

      <form onSubmit={onSubmit} className="space-y-4 mt-6">
        {selections.map((selection, index) => (
          <SelectionRow
            key={index}
            index={index}
            selection={selection}
            teams={teams}
            players={players}
            onTeamChange={onTeamChange}
            onPlayerChange={onPlayerChange}
            disabled={disabled}
          />
        ))}
        
        {!disabled && (
          <button 
            type="submit" 
            className="w-full bg-emerald-600 text-white px-6 py-3 rounded-lg font-medium hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500/20 transition-all duration-300"
          >
            {userSelections ? 'Update Selections' : 'Submit Selections'}
          </button>
        )}
      </form>
    </div>
  );
};

export default PlayerSelection;