import React, { useState } from 'react';
import TournamentManagement from './TournamentManagement';
import PlayerManagement from './PlayerManagement';
import PlayerMapping from './PlayerMapping';
import DataSync from './DataSync';
import LeagueManagement from './LeagueManagement';

const GolfAdmin = () => {
  const [activeTab, setActiveTab] = useState('tournament');

  const tabs = [
    { id: 'tournament', name: 'Tournament', Component: TournamentManagement },
    { id: 'players', name: 'Players', Component: PlayerManagement },
    { id: 'mapping', name: 'Name Mapping', Component: PlayerMapping },
    { id: 'leagues', name: 'Leagues', Component: LeagueManagement },
    { id: 'sync', name: 'Data Sync', Component: DataSync }
  ];

  return (
    <div>
      {/* Section Title */}
      <div className="mb-6">
        <h1 className="text-2xl font-bold text-gray-900">Golf Administration</h1>
      </div>

      {/* Sub Navigation */}
      <div className="border-b border-gray-200 mb-6">
        <nav className="flex space-x-8">
          {tabs.map(tab => (
            <button
              key={tab.id}
              onClick={() => setActiveTab(tab.id)}
              className={`py-4 px-1 border-b-2 font-medium text-sm
                ${activeTab === tab.id
                  ? 'border-blue-500 text-blue-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                } transition-colors duration-200`}
            >
              {tab.name}
            </button>
          ))}
        </nav>
      </div>

      {/* Content Area */}
      <div className="space-y-6">
        {tabs.map(tab => (
          activeTab === tab.id && <tab.Component key={tab.id} />
        ))}
      </div>
    </div>
  );
};

export default GolfAdmin;