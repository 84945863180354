import BaseApi, { API_ENDPOINTS } from '../../base/baseApi';

class FootballAdminService extends BaseApi {
    async getCurrentGame() {
        try {
            const response = await this.get(API_ENDPOINTS.admin.football.currentGame);
            console.log('Football admin service response:', response);
            // Return the data property of the response
            return response?.data || null;
        } catch (error) {
            console.error('Error in getCurrentGame:', error);
            throw error;
        }
    }

    async createNewGame(name) {
        try {
            console.log('Creating new game:', name);
            const response = await this.post(API_ENDPOINTS.admin.football.games, { name });
            console.log('Game creation response:', response);
            return response;
        } catch (error) {
            console.error('Error creating new game:', error);
            throw error;
        }
    }

    async updateGameDeadline(gameId, deadline) {
        return this.put(API_ENDPOINTS.admin.football.updateDeadline(gameId), { deadline });
    }

    async syncTeamsAndPlayers() {
        try {
            // Use the full path directly from the API_ENDPOINTS
            const response = await this.post(API_ENDPOINTS.admin.football.sync.teamsPlayers);
            console.log('Teams and players sync response:', response);
            return response;
        } catch (error) {
            console.error('Error syncing teams and players:', error);
            throw error;
        }
    }

    async syncMatchData() {
        try {
            // Use the full path directly
            const response = await this.post(API_ENDPOINTS.admin.football.sync.matches);
            console.log('Match data sync response:', response);
            return response;
        } catch (error) {
            console.error('Error syncing match data:', error);
            throw error;
        }
    }

    async getTeamPlayers(teamId) {
        try {
            console.log('Fetching team players for scores:', teamId);
            const response = await this.get(API_ENDPOINTS.admin.football.teamPlayers(teamId));
            // Since we modified baseApi, handle the response structure
            return response?.data || [];
        } catch (error) {
            console.error('Error getting team players:', error);
            throw error;
        }
    }

    async getAllTeamPlayers(teamId) {
        try {
            console.log('Fetching all players for team:', teamId);
            const response = await this.get(API_ENDPOINTS.admin.football.allTeamPlayers(teamId));
            console.log('Service response:', response);
            // Now response will be { success: true, data: [...] }
            if (response?.success && Array.isArray(response.data)) {
                return response.data;
            }
            return [];
        } catch (error) {
            console.error('Error getting all team players:', error);
            throw error;
        }
    }

    async togglePopularPlayer(playerId) {
        try {
            const response = await this.post(API_ENDPOINTS.admin.football.togglePopularPlayer(playerId));
            return response;
        } catch (error) {
            console.error('Error toggling popular player:', error);
            throw error;
        }
    }

    async updateScores(teamId, scoringPlayers) {
        return this.post(API_ENDPOINTS.admin.football.updateScores, {
            teamId,
            scoringPlayers
        });
    }

    async markMatchComplete(teamId) {
        return this.post(API_ENDPOINTS.admin.football.markMatchComplete, { teamId });
    }

    async getGameweekResults() {
        try {
            return this.get(API_ENDPOINTS.admin.football.gameweekResults);
        } catch (error) {
            console.error('Error getting gameweek results:', error);
            throw error;
        }
    }
}

const footballAdminService = new FootballAdminService();
export { footballAdminService };