import { useState, useCallback } from 'react';
import { footballAdminService } from '../../api/services/admin/footballAdminService';
import useFootballBase from './useFootballBase';

const useScoreManagement = () => {
  const { showMessage, refreshData } = useFootballBase();
  const [selectedTeam, setSelectedTeam] = useState('');
  const [players, setPlayers] = useState([]);
  const [scoringPlayers, setScoringPlayers] = useState([]);
  const [loadingPlayers, setLoadingPlayers] = useState(false);
  const [updatingScores, setUpdatingScores] = useState(false);

  const fetchTeamPlayers = useCallback(async (teamId) => {
    try {
        setLoadingPlayers(true);
        console.log('Fetching players for team:', teamId);
        const response = await footballAdminService.getTeamPlayers(teamId);
        console.log('Response from getTeamPlayers:', response);
        setPlayers(response || []);
    } catch (error) {
        showMessage(error.message, true);
        setPlayers([]);
    } finally {
        setLoadingPlayers(false);
    }
}, [showMessage]);

  const handleTeamChange = async (teamId) => {
    setSelectedTeam(teamId);
    if (teamId) {
      await fetchTeamPlayers(teamId);
    } else {
      setPlayers([]);
    }
    setScoringPlayers([]);
  };

  const toggleScoringPlayer = (playerId) => {
    setScoringPlayers(prev => {
      if (prev.includes(playerId)) {
        return prev.filter(id => id !== playerId);
      }
      return [...prev, playerId];
    });
  };

  const updateScores = async () => {
    if (!selectedTeam) {
        showMessage('Please select a team', true);
        return;
    }

    try {
        setUpdatingScores(true);
        await footballAdminService.updateScores(selectedTeam, scoringPlayers);
        await refreshData();
        showMessage('Scores updated successfully!');
        // Reset selections
        setSelectedTeam('');
        setScoringPlayers([]);
        setPlayers([]);
    } catch (error) {
        showMessage(error.message, true);
    } finally {
        setUpdatingScores(false);
    }
};

  const markMatchComplete = async (teamId) => {
    try {
      setUpdatingScores(true);
      await footballAdminService.markMatchComplete(teamId);
      await refreshData();
      showMessage('Match marked as complete!');
    } catch (error) {
      showMessage(error.message, true);
    } finally {
      setUpdatingScores(false);
    }
  };

  return {
    selectedTeam,
    players,
    scoringPlayers,
    loadingPlayers,
    updatingScores,
    handleTeamChange,
    toggleScoringPlayer,
    updateScores,
    markMatchComplete
  };
};

export default useScoreManagement;