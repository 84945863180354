import React from 'react';
import { Clock } from 'lucide-react';

const FixtureList = ({ fixtures }) => {
  return (
    <div className="bg-white rounded-lg shadow-sm">
      <div className="border-b border-gray-200">
        <h2 className="text-lg font-bold px-4 py-3">Fixtures</h2>
      </div>

      {/* Desktop version */}
      <div className="hidden md:block">
        <table className="w-full">
          <thead>
            <tr className="border-b border-gray-100 bg-gray-50">
              <th className="text-left py-3 px-4 text-sm font-medium text-gray-600">Home</th>
              <th className="text-left py-3 px-4 text-sm font-medium text-gray-600">Away</th>
              <th className="text-left py-3 px-4 text-sm font-medium text-gray-600">Kick-off</th>
            </tr>
          </thead>
          <tbody>
            {fixtures.map((match) => (
              <tr key={match._id} className="border-b border-gray-100">
                <td className="py-3 px-4 font-medium">{match.homeTeam}</td>
                <td className="py-3 px-4 font-medium">{match.awayTeam}</td>
                <td className="py-3 px-4 text-gray-600">
                  <div className="flex items-center">
                    <Clock className="w-4 h-4 mr-2 text-gray-400" />
                    {new Date(match.kickoffTime).toLocaleString(undefined, {
                      weekday: 'short',
                      day: 'numeric',
                      month: 'short',
                      hour: '2-digit',
                      minute: '2-digit'
                    })}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Mobile version */}
      <div className="md:hidden">
        <div className="divide-y divide-gray-100">
          {fixtures.map((match) => (
            <div key={match._id} className="px-4 py-3">
              <div className="grid grid-cols-7 items-center gap-2">
                <div className="col-span-3 text-right font-medium">{match.homeTeam}</div>
                <div className="col-span-1 text-xs text-gray-400 text-center">vs</div>
                <div className="col-span-3 font-medium">{match.awayTeam}</div>
              </div>
              <div className="flex items-center justify-center mt-1">
                <Clock className="w-3 h-3 mr-1 text-gray-400" />
                <span className="text-xs text-gray-500">
                  {new Date(match.kickoffTime).toLocaleString(undefined, {
                    weekday: 'short',
                    hour: '2-digit',
                    minute: '2-digit'
                  })}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FixtureList;