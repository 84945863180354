const API_BASE_URL = process.env.REACT_APP_API_URL?.endsWith('/api') 
  ? process.env.REACT_APP_API_URL.slice(0, -4) 
  : process.env.REACT_APP_API_URL;

  class BaseApi {
    constructor() {
      this.baseURL = `${API_BASE_URL}/api`;
      this.publicEndpoints = [
        // Auth endpoints
        '/auth/login',
        '/auth/register',
        '/auth/forgot-password',
        '/auth/reset-password',
        
        // Football public endpoints
        '/football/current-game-fixtures',
        '/football/public-entry-count',
        '/football/current-game-info',
        
        // Golf public endpoints
        '/golf/current-game-info',
        '/golf/current-game', 
        '/golf/tournament-leaderboard',
        '/golf/players',
        '/golf/public/tournament', 
        
        // Rugby public endpoints
        '/rugby/current-game-info',
        '/rugby/current-game-fixtures',
        '/rugby/public/current-game'
      ];
  }

  async fetchWithAuth(endpoint, options = {}) {
    console.log('fetchWithAuth called:', {
      endpoint,
      options,
      publicEndpoints: this.publicEndpoints
    });
  
    const isPublicEndpoint = this.publicEndpoints.some(publicPath => {
      const matches = endpoint.startsWith(publicPath);
      console.log(`Checking endpoint ${endpoint} against ${publicPath}:`, matches);
      return matches;
    });
  
    console.log('Auth check:', {
      endpoint,
      isPublic: isPublicEndpoint,
      hasToken: !!localStorage.getItem('token')
    });
  
    const headers = {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-store, no-cache, must-revalidate',
      'Pragma': 'no-cache',
      ...options.headers,
    };
  
    if (!isPublicEndpoint) {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No auth token provided');
      }
      headers['Authorization'] = `Bearer ${token}`;
    }
  
    const url = `${this.baseURL}${endpoint}`;
    console.log('Making request:', {
      url,
      method: options.method || 'GET',
      headers,
      isPublicEndpoint,
      endpoint
    });

    try {
      const response = await fetch(url, { 
        ...options, 
        headers,
        credentials: 'include'
      });

      const responseText = await response.text();

      if (!response.ok) {
        let errorMessage;
        try {
          const errorData = JSON.parse(responseText);
          errorMessage = errorData.message || `HTTP error! status: ${response.status}`;
        } catch (e) {
          errorMessage = responseText || `HTTP error! status: ${response.status}`;
        }
        throw new Error(errorMessage);
      }

      if (responseText) {
        try {
            const parsedData = JSON.parse(responseText);
            return parsedData;  // Return the full response object
        } catch (e) {
            console.error('Error parsing response:', e);
            return null;
        }
    }
    return null;
    } catch (error) {
      // Only log non-sensitive error details
      const errorMessage = endpoint.startsWith('/auth/')
        ? 'Authentication request failed'
        : `API request failed: ${error.message}`;
      console.error(errorMessage);
      throw error;
    }
  }

  async get(endpoint) {
    return this.fetchWithAuth(endpoint);
  }

  async post(endpoint, data) {
    return this.fetchWithAuth(endpoint, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  }

  async put(endpoint, data) {
    return this.fetchWithAuth(endpoint, {
      method: 'PUT',
      body: JSON.stringify(data),
    });
  }

  async delete(endpoint) {
    return this.fetchWithAuth(endpoint, {
      method: 'DELETE',
    });
  }

  async getPublic(endpoint) {
    console.log('getPublic called with endpoint:', endpoint);
    return this.fetchWithAuth(endpoint, {
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-store, no-cache, must-revalidate',
        'Pragma': 'no-cache'
      }
    });
  }

}

export const API_ENDPOINTS = {
    auth: {
      login: '/auth/login',
      register: '/auth/register',
      user: '/auth/user',
      forgotPassword: '/auth/forgot-password',
      resetPassword: '/auth/reset-password',
    },
    football: {
      base: '/football',
      // Add public endpoints
      currentGameInfo: '/football/current-game-info',  
      currentGameFixtures: '/football/current-game-fixtures',
      publicEntryCount: '/football/public-entry-count',
      // Protected endpoints
      teams: '/football/teams',
      players: '/football/players',
      popularPlayers: '/football/popular-players',  
      selections: '/football/selections',
      currentGame: '/football/current-game',
      gameweekResults: '/football/gameweek-results',
      userSelections: '/football/my-selections',
    },
  
    golf: {
      base: '/golf',
      // Public endpoints
      currentGameInfo: '/golf/current-game-info',
      tournament: '/golf/tournament-leaderboard',
      players: '/golf/players',
      // Protected endpoints
      currentGame: '/golf/current-game',
      gameLeaderboard: '/golf/game-leaderboard',
      selections: '/golf/selections',
      mySelections: '/golf/my-selections',
      leagues: '/golf/leagues',
    },
  
    rugby: {
      base: '/rugby',
      // Public endpoints
      currentGameInfo: '/rugby/current-game-info',
      currentGameFixtures: '/rugby/current-game-fixtures',
      publicCurrentGame: '/rugby/public/current-game',
      // Protected endpoints
      currentGame: '/rugby/current-game',
      matches: '/rugby/matches',
      selections: '/rugby/selections',
      mySelections: '/rugby/selections/my-selections',
      leaderboard: '/rugby/leaderboard',
      overallLeaderboard: '/rugby/leaderboard/overall',
    },

    admin: {
      football: {
          base: '/admin/football',
          currentGame: '/admin/football/current-game',
          games: '/admin/football/games',     
          sync: {
            teamsPlayers: '/admin/football/sync/teams-players',
            matches: '/admin/football/sync/matches'
          },       
          gameweekResults: '/admin/football/gameweek-results',
          teamPlayers: (teamId) => `/admin/football/team-players/${teamId}`,
          allTeamPlayers: (teamId) => `/admin/football/team-all-players/${teamId}`,
          updateScores: '/admin/football/update-scores',
          togglePopularPlayer: (playerId) => `/admin/football/players/${playerId}/toggle-popular`
      },  
        nfl: '/admin/nfl',
        golf: {
            base: '/admin/golf',
            currentGame: '/admin/golf/current-game',
            games: '/admin/golf/games',
            sync: '/admin/golf/sync',
            players: '/admin/golf/players',
            appendPlayers: '/admin/golf/append-players',
            standings: '/admin/golf/standings',
            playerMappings: '/admin/golf/player-mappings',
            updateOdds: (playerId) => `/admin/golf/players/${playerId}/odds`,
            syncTournament: '/admin/golf/sync/tournament',
            leagues: '/admin/golf/leagues',
            leaguesForGame: '/admin/golf/leagues/for-game'
        },
        rugby: {
          base: '/admin/rugby',
          currentGame: '/admin/rugby/current-game',
          games: '/admin/rugby/games',
          sync: '/admin/rugby/sync',
          matches: '/admin/rugby/gameweek-results', 
          updateScore: (matchId) => `/admin/rugby/matches/${matchId}/result`
        },
    }
    };
  
  export default BaseApi;