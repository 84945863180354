import React from 'react';
import { useFeatureFlags } from '../../../context/FeatureFlagsContext';
import GameCard from '../../common/GameCard';
import { gameTypeColors } from '../../../utils/colors';

const GameSection = ({ currentGame, rugbyGame, golfGame, isMainSite }) => {
  const { SHOW_FOOTBALL, SHOW_NFLSC, SHOW_RUGBY, SHOW_GOLF } = useFeatureFlags();

  const games = [
    SHOW_FOOTBALL && {
      title: "Football - 5 Scorers",
      deadline: currentGame?.deadline,
      links: [{ to: "/football", text: "Play Game" }],
      type: 'football'
    },
    isMainSite && SHOW_NFLSC && {
      title: "NFL Super Contest",
      links: [{ to: "/nfl-sc", text: "Play Game" }],
      type: 'nfl-sc'
    },
    isMainSite && SHOW_RUGBY && {
      title: "Six Nations",
      deadline: rugbyGame?.deadline,
      links: [{ to: "/rugby", text: "Play Now" }],
      type: 'rugby'
    },
    isMainSite && SHOW_GOLF && {
      title: "Golf Tournament",
      deadline: golfGame?.deadline,
      links: [{ to: "/golf", text: "Play Now" }],
      type: 'golf'
    }
  ].filter(Boolean);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      {games.map((game, index) => (
        <div key={index} className="w-full">
          <GameCard 
            {...game}
            style="detailed" 
            showPrizePool={true}
          />
        </div>
      ))}
    </div>
  );
};

export default GameSection;