import React, { useState, useEffect } from 'react';
import { Users, Plus } from 'lucide-react';
import { golfService } from '../../../api/services';

const LeagueEnrollment = ({ userSelections }) => {
    const [availableLeagues, setAvailableLeagues] = useState([]);
    const [userLeagues, setUserLeagues] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [joiningLeague, setJoiningLeague] = useState(false);
  
    const loadLeagues = async () => {
      try {
        console.log('Fetching leagues...');
        const response = await golfService.getAvailableLeagues();
        console.log('Leagues response:', response);
        
        if (!response || !response.leagues) {
          throw new Error('Invalid league response');
        }
        
        // Separate user's leagues from available leagues
        const userMemberships = [];
        const available = [];
        
        response.leagues.forEach(league => {
          const isMember = league.members.some(
            member => member.user === response.currentUserId
          );
          
          if (isMember) {
            userMemberships.push(league);
          } else {
            available.push(league);
          }
        });
        
        console.log('User leagues:', userMemberships);
        console.log('Available leagues:', available);
        
        setUserLeagues(userMemberships);
        setAvailableLeagues(available);
      } catch (err) {
        console.error('Error loading leagues:', err);
        setError('Failed to load leagues');
      } finally {
        setLoading(false);
      }
    };
  
    useEffect(() => {
      if (userSelections) {
        loadLeagues();
      }
    }, [userSelections]);
  
    const handleJoinLeague = async (leagueId) => {
      try {
        setJoiningLeague(true);
        setError(null);
        
        await golfService.joinLeague(leagueId);
        
        // Reload leagues immediately after joining
        await loadLeagues();
      } catch (err) {
        console.error('Error joining league:', err);
        setError(err.message || 'Failed to join league');
      } finally {
        setJoiningLeague(false);
      }
    };

  console.log('LeagueEnrollment render state:', {
    loading,
    availableLeagues: availableLeagues.length,
    userLeagues: userLeagues.length,
    error,
    joiningLeague
  });

  if (loading) {
    return (
      <div className="mt-6 p-4 bg-gray-50 rounded-lg">
        Loading leagues...
      </div>
    );
  }

  return (
    <div className="mt-6">
      {/* User's current league memberships */}
      {userLeagues.length > 0 && (
        <div className="mb-6">
          <h3 className="font-medium mb-3">Your League Memberships</h3>
          <div className="space-y-2">
            {userLeagues.map(league => (
              <div 
                key={league._id}
                className="p-4 bg-blue-50 border border-blue-100 rounded-lg"
              >
                <div className="flex items-center">
                  <Users className="w-5 h-5 text-blue-500 mr-2" />
                  <div>
                    <h3 className="font-medium">{league.name}</h3>
                    <p className="text-sm text-gray-600">
                      {league.members.length} member{league.members.length !== 1 ? 's' : ''}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Available leagues to join */}
      {availableLeagues.length > 0 && (
        <>
          <h3 className="font-medium mb-3">Available Leagues</h3>
          <div className="space-y-2">
            {error && (
              <div className="text-red-600 text-sm p-2 bg-red-50 rounded-lg">
                {error}
              </div>
            )}
            
            {availableLeagues.map(league => (
              <div 
                key={league._id}
                className="flex items-center justify-between p-3 bg-gray-50 rounded-lg"
              >
                <div>
                  <span className="font-medium">{league.name}</span>
                  <div className="text-sm text-gray-500">
                    {league.members.length} member{league.members.length !== 1 ? 's' : ''}
                  </div>
                </div>
                <button
                  onClick={() => handleJoinLeague(league._id)}
                  disabled={joiningLeague}
                  className="flex items-center px-3 py-1 text-sm font-medium text-blue-600 
                    border border-blue-600 rounded-lg hover:bg-blue-50 
                    disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  <Plus className="w-4 h-4 mr-1" />
                  {joiningLeague ? 'Joining...' : 'Join League'}
                </button>
              </div>
            ))}
          </div>
        </>
      )}
      
      {availableLeagues.length === 0 && userLeagues.length === 0 && (
        <p className="text-gray-600 text-sm">
          No leagues available for this tournament.
        </p>
      )}
    </div>
  );
};

export default LeagueEnrollment;