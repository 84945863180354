import React from 'react';
import { Calendar, Clock } from 'lucide-react';

const FixtureList = ({ matches }) => {
  return (
    <div className="bg-gradient-to-r from-primary-50 to-white rounded-lg shadow-sm">
  <div className="border-b border-primary-100">
    <h2 className="text-lg font-bold px-4 py-3 text-primary-600">Fixtures</h2>
  </div>

      {/* Desktop version */}
      <div className="hidden md:block">
        <table className="w-full">
        <thead>
    <tr className="border-b border-primary-100 bg-primary-50">
      <th className="text-left py-3 px-4 text-sm font-medium text-primary-600">Home</th>
              <th className="text-left py-3 px-4 text-sm font-medium text-primary-600">Away</th>
              <th className="text-left py-3 px-4 text-sm font-medium text-primary-600">Kick-off</th>
              <th className="text-left py-3 px-4 text-sm font-medium text-primary-600">Score</th>
            </tr>
          </thead>
          <tbody>
            {matches.map((match) => (
              <tr key={match._id} className="border-b border-primary-100">
                <td className="py-3 px-4 font-medium text-primary-600">{match.homeTeam}</td>
                <td className="py-3 px-4 font-medium text-primary-600">{match.awayTeam}</td>
                <td className="py-3 px-4 text-gray-600">
                  <div className="flex items-center">
                    <Clock className="w-4 h-4 mr-2 text-gray-400" />
                    {new Date(match.kickoffTime).toLocaleString()}
                  </div>
                </td>
                <td className="py-3 px-4">
                  {match.result?.isComplete ? (
                    <span className="font-medium">
                      {match.result.homeScore} - {match.result.awayScore}
                    </span>
                  ) : (
                    <span className="text-gray-400">-</span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Mobile version */}
      <div className="md:hidden">
        <div className="divide-y divide-gray-100">
          {matches.map((match) => (
            <div key={match._id} className="px-4 py-3">
              <div className="grid grid-cols-7 items-center gap-2 mb-2">
                <div className="col-span-3 text-right font-medium">{match.homeTeam}</div>
                <div className="col-span-1 text-xs text-gray-400 text-center">vs</div>
                <div className="col-span-3 font-medium">{match.awayTeam}</div>
              </div>
              {match.result?.isComplete ? (
                <div className="text-center font-medium">
                  {match.result.homeScore} - {match.result.awayScore}
                </div>
              ) : (
                <div className="flex items-center justify-center text-sm text-gray-500">
                  <Clock className="w-3 h-3 mr-1" />
                  {new Date(match.kickoffTime).toLocaleString()}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FixtureList;
