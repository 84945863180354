import { useState } from 'react';
import { footballAdminService } from '../../api/services/admin/footballAdminService';
import useFootballBase from './useFootballBase';

const useDataSync = () => {
  const { showMessage, refreshData } = useFootballBase();
  const [syncing, setSyncing] = useState(false);

  const syncMatchData = async () => {
    try {
      setSyncing(true);
      showMessage('Syncing match data...');
      await footballAdminService.syncMatchData();
      await refreshData();
      showMessage('Match data synced successfully!');
    } catch (error) {
      showMessage(error.message, true);
    } finally {
      setSyncing(false);
    }
  };

  const syncTeamsAndPlayers = async () => {
    try {
      setSyncing(true);
      showMessage('Updating teams and players data...');
      await footballAdminService.syncTeamsAndPlayers();
      await refreshData();
      showMessage('Teams and players updated successfully!');
    } catch (error) {
      showMessage(error.message, true);
    } finally {
      setSyncing(false);
    }
  };

  const createNewGame = async (name) => {
    try {
      setSyncing(true);
      showMessage('Creating new game...');
      await footballAdminService.createNewGame(name);
      await refreshData();
      showMessage('New game created successfully!');
    } catch (error) {
      showMessage(error.message, true);
    } finally {
      setSyncing(false);
    }
  };

  const updateDeadline = async (gameId, deadline) => {
    try {
      setSyncing(true);
      await footballAdminService.updateGameDeadline(gameId, deadline);
      await refreshData();
      showMessage('Deadline updated successfully!');
    } catch (error) {
      showMessage(error.message, true);
    } finally {
      setSyncing(false);
    }
  };

  return {
    syncing,
    syncMatchData,
    syncTeamsAndPlayers,
    createNewGame,
    updateDeadline
  };
};

export default useDataSync;