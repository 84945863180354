import React from 'react';
import useNFLSCAdmin from '../../hooks/useNFLSCAdmin';
import LoadingSpinner from '../ui/state/LoadingSpinner';
import styles from './NFLSCAdmin.module.css';

function NFLSCAdmin() {
  const {
    currentNFLSCGame,
    newNFLSCGameName,
    NFLSCSyncMessage,
    loading,
    error,
    setNewNFLSCGameName,
    handleCreateNewNFLSCGame,
    handleProcessNFLSCResults,
  } = useNFLSCAdmin();

  if (loading) return <LoadingSpinner />;

  return (
    <div className={styles.section}>
      <h2>NFL Supercontest Administration</h2>
      {error && <p className={styles.error}>{error}</p>}
      {NFLSCSyncMessage && <p className={styles.success}>{NFLSCSyncMessage}</p>}

      <div className={styles.subsection}>
        <h3>Current NFLSC Game</h3>
        {currentNFLSCGame ? (
          <div>
            <p>Game Name: {currentNFLSCGame.name}</p>
            <p>Current Deadline: {new Date(currentNFLSCGame.deadline).toLocaleString()}</p>
          </div>
        ) : (
          <p>No active NFLSC game.</p>
        )}
      </div>

      <div className={styles.subsection}>
        <h3>Create New NFLSC Game</h3>
        <input
          type="text"
          value={newNFLSCGameName}
          onChange={(e) => setNewNFLSCGameName(e.target.value)}
          placeholder="Enter new NFLSC game name"
          className={styles.input}
        />
        <button onClick={handleCreateNewNFLSCGame} className={styles.button}>
          Create New NFLSC Game
        </button>
      </div>

      <div className={styles.subsection}>
        <h3>Process NFLSC Results</h3>
        <button onClick={handleProcessNFLSCResults} className={styles.button}>
          Process NFLSC Results
        </button>
      </div>
    </div>
  );
}

export default NFLSCAdmin;