import React, { useState, useEffect } from 'react';
import { Edit, Check, AlertCircle, Search, X } from 'lucide-react';

const PlayerSelectionGrid = ({
  availablePlayers,
  onSubmit,
  initialSelections = null,
  isEditing = false,
  selectedPlayers,
  setSelectedPlayers
}) => {
  const [combinedOdds, setCombinedOdds] = useState(0);
  const [isValid, setIsValid] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  // Update initialSelections effect
  useEffect(() => {
    if (initialSelections?.length > 0 && availablePlayers?.length > 0) {
      console.log('Setting initial selections:', initialSelections);
      const mappedSelections = initialSelections.map(selection => {
        const player = availablePlayers.find(p => p._id === selection._id);
        return player;
      }).filter(Boolean);
      
      console.log('Mapped selections:', mappedSelections);
      setSelectedPlayers(mappedSelections); // Use the prop setter
    }
  }, [initialSelections, availablePlayers, setSelectedPlayers]);

  // Calculate combined odds and validate selection
  useEffect(() => {
    const total = selectedPlayers.reduce((acc, player) => acc + player.odds, 0);
    setCombinedOdds(total);
    
    setIsValid(
      selectedPlayers.length === 3 && 
      total >= 125 &&
      new Set(selectedPlayers.map(p => p._id)).size === 3
    );
  }, [selectedPlayers]);

  const handlePlayerSelection = (player) => {
    if (selectedPlayers.find(p => p._id === player._id)) {
      setSelectedPlayers(selectedPlayers.filter(p => p._id !== player._id));
    } else if (selectedPlayers.length < 3) {
      setSelectedPlayers([...selectedPlayers, player]);
    }
  };

  const sortedAndFilteredPlayers = [...availablePlayers]
    .sort((a, b) => a.odds - b.odds)
    .filter(player => 
      player.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const renderSelectionSummary = () => {
      const emptySlots = 3 - selectedPlayers.length;
      
      return (
        <div className="flex gap-2 mb-3">
          {selectedPlayers.map((player, index) => (
            <div 
              key={player._id}
              className="flex-1 bg-blue-50 border border-blue-200 rounded-lg p-2 sm:p-3 relative"
            >
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handlePlayerSelection(player);
                }}
                className="absolute top-1 right-1 sm:top-2 sm:right-2 text-gray-400 hover:text-gray-600"
              >
                <X className="w-4 h-4" />
              </button>
              <div className="pr-6">
                <div className="flex items-center gap-1 sm:gap-2">
                  <div className="w-4 h-4 sm:w-5 sm:h-5 bg-blue-500 rounded-full flex items-center 
                    justify-center text-white text-xs">
                    {index + 1}
                  </div>
                  <h3 className="font-medium text-sm truncate">{player.name}</h3>
                </div>
                {/* Remove odds on mobile */}
                <p className="text-xs text-gray-600 mt-1 hidden sm:block">Odds: {player.odds}/1</p>
              </div>
            </div>
          ))}
          {[...Array(emptySlots)].map((_, i) => (
            <div 
              key={`empty-${i}`}
              className="flex-1 border border-dashed border-gray-300 rounded-lg p-2 sm:p-3 
                bg-gray-50 flex items-center justify-center"
            >
              <span className="text-xs sm:text-sm text-gray-400">Player {selectedPlayers.length + i + 1}</span>
            </div>
          ))}
        </div>
      );
    };
  
    return (
      <div className="relative pb-32 sm:pb-40">
        <div className="bg-gradient-to-br from-white to-blue-50 rounded-lg shadow-sm p-3 sm:p-4">
          <div className="flex flex-col sm:flex-row sm:items-center gap-3 sm:gap-0 sm:justify-between mb-4">
            <div>
              <p className="text-sm text-gray-600">
                {selectedPlayers.length}/3 Selected
              </p>
            </div>
            
            <div className="relative w-full sm:w-64">
              <input
                type="text"
                placeholder="Search players..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full pl-8 pr-4 py-2 border border-gray-300 rounded-lg
                  focus:outline-none focus:ring-2 focus:ring-blue-500/20"
              />
              <Search className="absolute left-2 top-2.5 w-4 h-4 text-gray-400" />
            </div>
          </div>
     
          <div className="grid gap-2 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
            {sortedAndFilteredPlayers.map((player) => {
              const isSelected = selectedPlayers.some(p => p._id === player._id);
              
              return (
                <div
                  key={player._id}
                  onClick={() => handlePlayerSelection(player)}
                  className={`p-2 sm:p-3 rounded-lg border ${
                    isSelected 
                      ? 'bg-blue-500/5 border-blue-200' 
                      : 'border-gray-200'
                  } ${
                    selectedPlayers.length >= 3 && !isSelected 
                      ? 'opacity-50 cursor-not-allowed' 
                      : 'cursor-pointer'
                  }`}
                >
                  <div className="flex justify-between items-center">
                    <div>
                      <h3 className="font-medium text-sm">{player.name}</h3>
                      <p className="text-xs text-gray-600">Odds: {player.odds}/1</p>
                    </div>
                    {isSelected && (
                      <div className="w-5 h-5 bg-blue-500 rounded-full flex items-center 
                        justify-center text-white text-xs">
                        {selectedPlayers.findIndex(p => p._id === player._id) + 1}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
     
        <div className="fixed bottom-0 left-0 right-0 bg-white border-t shadow-lg">
          <div className="max-w-6xl mx-auto p-3 sm:p-4">
            {renderSelectionSummary()}
            
            <div className="h-px bg-gray-200 -mx-3 sm:-mx-4 mb-3"></div>
     
            <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-3 sm:gap-0">
              <div className="flex flex-wrap items-center gap-2 sm:gap-4">
                <div className="flex items-center gap-2">
                  <span className="text-sm font-medium">Combined:</span>
                  <span className="text-lg font-bold">{combinedOdds.toFixed(1)}/1</span>
                </div>
                
                {isValid ? (
                  <div className="flex items-center text-green-600">
                    <Check className="w-4 h-4 mr-1" />
                    <span className="text-sm">Valid</span>
                  </div>
                ) : selectedPlayers.length > 0 && (
                  <div className="flex items-center text-amber-600">
                    <AlertCircle className="w-4 h-4 mr-1" />
                    <span className="text-sm">
                      {selectedPlayers.length < 3 
                        ? 'Select 3 players'
                        : 'Min. odds 125/1'}
                    </span>
                  </div>
                )}
              </div>
     
              <button
                onClick={() => onSubmit(selectedPlayers)}
                disabled={!isValid}
                className="w-full sm:w-auto bg-blue-600 text-white px-6 py-2.5 rounded-lg 
                  font-medium focus:outline-none focus:ring-2 
                  focus:ring-blue-500/20 transition-all duration-300 
                  disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isEditing ? 'Update Selections' : 'Submit Selections'}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default PlayerSelectionGrid;