import React, { useState, useEffect } from 'react';
import { Calendar } from 'lucide-react';
import Card from '../../shared/Card';
import useDataSync from '../../../../hooks/football/useDataSync';
import useFootballBase from '../../../../hooks/football/useFootballBase';

const GameManagement = () => {
  const { currentGame } = useFootballBase();
  const { syncing, createNewGame, updateDeadline } = useDataSync();
  const [newGameName, setNewGameName] = useState('');
  const [deadline, setDeadline] = useState('');

  useEffect(() => {
    if (currentGame?.deadline) {
      setDeadline(new Date(currentGame.deadline).toISOString().slice(0, 16));
    }
  }, [currentGame]);

  return (
    <Card>
      <div className="space-y-6">
        <div>
          <h2 className="text-lg font-semibold mb-4">Create New Game</h2>
          <div className="flex gap-4">
            <input
              type="text"
              value={newGameName}
              onChange={(e) => setNewGameName(e.target.value)}
              placeholder="Enter gameweek name"
              className="flex-1 px-3 py-2 border rounded-lg"
            />
            <button
              onClick={() => {
                createNewGame(newGameName);
                setNewGameName('');
              }}
              disabled={!newGameName.trim() || syncing}
              className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 
                transition-colors disabled:bg-green-300"
            >
              Create Game
            </button>
          </div>
        </div>

        {currentGame && (
          <div className="border-t pt-6">
            <h2 className="text-lg font-semibold mb-4">Update Deadline</h2>
            <div className="flex items-center gap-4">
              <div className="flex-1">
                <label className="block text-sm text-gray-600 mb-1">
                  <Calendar className="w-4 h-4 inline mr-1" />
                  Current Deadline: {new Date(currentGame.deadline).toLocaleString()}
                </label>
                <input
                  type="datetime-local"
                  value={deadline}
                  onChange={(e) => setDeadline(e.target.value)}
                  className="w-full px-3 py-2 border rounded-lg"
                />
              </div>
              <button
                onClick={() => updateDeadline(currentGame._id, new Date(deadline))}
                disabled={!deadline || syncing}
                className="px-4 py-2 bg-orange-600 text-white rounded-lg hover:bg-orange-700 
                  transition-colors disabled:bg-orange-300"
              >
                Update Deadline
              </button>
            </div>
          </div>
        )}
      </div>
    </Card>
  );
};

export default GameManagement;