import React from 'react';
import { cn } from '../../utils/cn';  // We'll create this utility

const Card = ({ 
  children, 
  title,
  className,
  headerClassName,
  bodyClassName,
  variant = 'default'  // 'default' | 'interactive' | 'bordered'
}) => {
  const variants = {
    default: 'bg-white shadow-md',
    interactive: 'bg-white shadow-md hover:shadow-lg transition-shadow duration-200',
    bordered: 'bg-white border border-gray-200'
  };

  return (
    <div className={cn(
      'rounded-lg',
      variants[variant],
      className
    )}>
      {title && (
        <div className={cn(
          'px-6 py-4',
          headerClassName
        )}>
          <h2 className="text-xl font-semibold text-gray-900">{title}</h2>
        </div>
      )}
      <div className={cn(
        'px-6 py-4',
        !title && 'pt-6',
        bodyClassName
      )}>
        {children}
      </div>
    </div>
  );
};

export default Card;