import React, { useState } from 'react';
import { Calendar } from 'lucide-react';
import Card from '../shared/Card';
import useRugbyManagement from '../../../hooks/rugby/useRugbyManagement';
import { LoadingSpinner } from '../../../components/ui/state';

const GameManagement = () => {
  const {
    currentGame,
    loading,
    error,
    syncMessage,
    createGame
  } = useRugbyManagement();

  const [gameweekName, setGameweekName] = useState('');
  const [deadline, setDeadline] = useState('');

  const handleCreateGame = async (e) => {
    e.preventDefault();
    try {
      await createGame({
        name: gameweekName,
        deadline
      });
      // Reset form
      setGameweekName('');
      setDeadline('');
    } catch (err) {
      // Error is handled by the hook
    }
  };

  if (loading) return <LoadingSpinner />;

  return (
    <div className="space-y-6">
      {error && (
        <div className="bg-red-50 border border-red-200 text-red-700 p-4 rounded-lg">
          {error}
        </div>
      )}

      {syncMessage && (
        <div className="bg-green-50 border border-green-200 text-green-700 p-4 rounded-lg">
          {syncMessage}
        </div>
      )}

      <Card title="Current Gameweek">
        {currentGame ? (
          <div className="space-y-3">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="text-sm font-medium text-gray-500">Gameweek</label>
                <p className="mt-1">{currentGame.name}</p>
              </div>
              <div>
                <label className="text-sm font-medium text-gray-500">Status</label>
                <p className="mt-1">{currentGame.status}</p>
              </div>
              <div>
                <label className="text-sm font-medium text-gray-500">Deadline</label>
                <p className="mt-1">{new Date(currentGame.deadline).toLocaleString()}</p>
              </div>
            </div>
          </div>
        ) : (
          <p className="text-gray-500">No active gameweek. Create a new gameweek to get started.</p>
        )}
      </Card>

      <Card title="Create Gameweek">
        <form onSubmit={handleCreateGame} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Gameweek Name
            </label>
            <input
              type="text"
              value={gameweekName}
              onChange={(e) => setGameweekName(e.target.value)}
              className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500/20 focus:border-blue-500"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Selection Deadline
            </label>
            <input
              type="datetime-local"
              value={deadline}
              onChange={(e) => setDeadline(e.target.value)}
              className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500/20 focus:border-blue-500"
              required
            />
          </div>

          <button
            type="submit"
            className="w-full bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500/20"
          >
            Create Gameweek
          </button>
        </form>
      </Card>
    </div>
  );
};

export default GameManagement;
