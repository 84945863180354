import { useState, useEffect, useCallback } from 'react';
import { footballAdminService } from '../../api/services/admin/footballAdminService';

const useFootballBase = () => {
  const [currentGame, setCurrentGame] = useState(null);
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [syncMessage, setSyncMessage] = useState(null);

  const fetchCurrentGame = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await footballAdminService.getCurrentGame();
      console.log('Response in useFootballBase:', response);
      
      // The response has currentGame and teams properties
      if (response) {
        setCurrentGame(response.currentGame || null);
        setTeams(response.teams || []);
      } else {
        setCurrentGame(null);
        setTeams([]);
      }
    } catch (err) {
      setError(err.message);
      console.error('Error fetching current game:', err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchCurrentGame();
  }, [fetchCurrentGame]);

  const clearMessage = () => {
    setSyncMessage(null);
    setError(null);
  };

  const showMessage = (message, isError = false) => {
    if (isError) {
      setError(message);
      setSyncMessage(null);
    } else {
      setSyncMessage(message);
      setError(null);
    }
    setTimeout(clearMessage, 5000);
  };

  return {
    currentGame,
    teams,
    loading,
    error,
    syncMessage,
    showMessage,
    refreshData: fetchCurrentGame
  };
};

export default useFootballBase;