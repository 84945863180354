import React from 'react';
import { Info } from 'lucide-react';

export const EmptyState = ({ 
  title = 'No data available',
  message = 'Please check back later.',
  icon = <Info className="w-12 h-12 text-gray-400" />,
  action = null
}) => {
  return (
    <div className="rounded-lg border p-8 bg-gray-50 flex flex-col items-center text-center space-y-4">
      <div className="mx-auto text-gray-400">
        {icon}
      </div>
      <h3 className="text-lg font-medium text-gray-900">
        {title}
      </h3>
      <p className="text-gray-500 max-w-sm">
        {message}
      </p>
      {action && (
        <div className="mt-6">
          {action}
        </div>
      )}
    </div>
  );
};