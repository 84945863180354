import React, { useState } from 'react';
import { Clock, Calendar, Users, Award } from 'lucide-react';
import Card from '../shared/Card';
import useDataSync from '../../../hooks/golf/useDataSync';
import LoadingSpinner from '../../ui/state/LoadingSpinner';

const DataSync = () => {
  const {
    lastSyncTime,
    lastAppendTime,
    loading,
    error,
    syncMessage,
    syncResults,
    appendResults,
    syncTournamentData,
    syncPlayers,
    appendPlayers
  } = useDataSync();
  
  const [expandedSection, setExpandedSection] = useState(null);

  if (loading) return <LoadingSpinner />;

  const formatLastUpdate = (timestamp) => {
    if (!timestamp) return 'Never';
    const date = new Date(timestamp);
    return date.toLocaleString();
  };
  
  const toggleSection = (section) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  return (
    <div className="space-y-6">
      {error && (
        <div className="bg-red-50 border border-red-200 text-red-700 p-4 rounded-lg">
          {error}
        </div>
      )}

      {syncMessage && (
        <div className="bg-green-50 border border-green-200 text-green-700 p-4 rounded-lg">
          {syncMessage}
        </div>
      )}

      <Card>
        <div className="space-y-6">
          <div className="flex justify-between items-center">
            <div>
              <h2 className="text-lg font-semibold">Data Synchronization</h2>
              <p className="text-sm text-gray-500 mt-1">
                Manage tournament data and player information
              </p>
            </div>
          </div>
          
          {/* Initial Player Sync - Use when setting up a new tournament on Monday */}
          <div className="border-t pt-4">
            <div 
              className="flex justify-between items-center cursor-pointer" 
              onClick={() => toggleSection('initialSync')}
            >
              <div className="flex items-center gap-2">
                <Calendar className="w-5 h-5 text-blue-600" />
                <h3 className="font-medium">Initial Player Sync</h3>
              </div>
              <div className="flex items-center gap-2 text-sm text-gray-500">
                <Clock className="w-4 h-4" />
                Last sync: {formatLastUpdate(lastSyncTime)}
              </div>
            </div>
            
            {expandedSection === 'initialSync' && (
              <div className="mt-4 pl-7">
                <p className="text-sm text-gray-600 mb-3">
                  <strong>Warning:</strong> This will replace ALL existing players for the current tournament. 
                  Only use this when initially setting up the tournament on Monday.
                </p>
                <button
                  onClick={syncPlayers}
                  className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                >
                  Sync All Players
                </button>
              </div>
            )}
          </div>
          
          {/* Append Players - Use to add new players mid-week */}
          <div className="border-t pt-4">
            <div 
              className="flex justify-between items-center cursor-pointer" 
              onClick={() => toggleSection('appendPlayers')}
            >
              <div className="flex items-center gap-2">
                <Users className="w-5 h-5 text-green-600" />
                <h3 className="font-medium">Append New Players</h3>
              </div>
              <div className="flex items-center gap-2 text-sm text-gray-500">
                <Clock className="w-4 h-4" />
                Last append: {formatLastUpdate(lastAppendTime)}
              </div>
            </div>
            
            {expandedSection === 'appendPlayers' && (
              <div className="mt-4 pl-7">
                <p className="text-sm text-gray-600 mb-3">
                  This will only add <strong>new players</strong> from the sheet without modifying existing players.
                  Use this to add players that join the tournament after your initial setup.
                </p>
                
                {appendResults && (
                  <div className="mb-3 p-3 bg-gray-50 rounded-lg text-sm">
                    <h4 className="font-medium mb-1">Last Append Results:</h4>
                    <ul className="list-disc pl-5">
                      <li>Added {appendResults.data?.playersCreated || 0} new players</li>
                      <li>Skipped {appendResults.data?.playersSkipped || 0} existing players</li>
                      {appendResults.data?.errors > 0 && (
                        <li className="text-red-600">Encountered {appendResults.data.errors} errors</li>
                      )}
                    </ul>
                    
                    {appendResults.data?.newPlayers?.length > 0 && (
                      <div className="mt-2">
                        <h5 className="font-medium">New Players Added:</h5>
                        <ul className="mt-1 max-h-48 overflow-y-auto">
                          {appendResults.data.newPlayers.map((player, idx) => (
                            <li key={idx} className="py-1 border-b border-gray-100 last:border-0">
                              {player.name} ({player.odds})
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                )}
                
                <button
                  onClick={appendPlayers}
                  className="flex items-center gap-2 px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors"
                >
                  Append New Players
                </button>
              </div>
            )}
          </div>
          
          {/* Tournament Data Sync */}
          <div className="border-t pt-4">
            <div 
              className="flex justify-between items-center cursor-pointer" 
              onClick={() => toggleSection('tournamentSync')}
            >
              <div className="flex items-center gap-2">
                <Award className="w-5 h-5 text-purple-600" />
                <h3 className="font-medium">Tournament Leaderboard Sync</h3>
              </div>
              <div className="flex items-center gap-2 text-sm text-gray-500">
                <Clock className="w-4 h-4" />
                Last sync: {formatLastUpdate(lastSyncTime)}
              </div>
            </div>
            
            {expandedSection === 'tournamentSync' && (
              <div className="mt-4 pl-7">
                <p className="text-sm text-gray-600 mb-3">
                  Synchronize live tournament scores and leaderboard data.
                  This is safe to run at any time during the tournament.
                </p>
                
                {syncResults && (
                  <div className="mb-3 p-3 bg-gray-50 rounded-lg text-sm">
                    <h4 className="font-medium mb-1">Last Sync Results:</h4>
                    <ul className="list-disc pl-5">
                      <li>Updated {syncResults.playersUpdated || 0} players</li>
                      {syncResults.failedMatches?.length > 0 && (
                        <li className="text-yellow-600">
                          {syncResults.failedMatches.length} players could not be matched
                        </li>
                      )}
                    </ul>
                  </div>
                )}
                
                <button
                  onClick={syncTournamentData}
                  className="flex items-center gap-2 px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors"
                >
                  Sync Tournament Data
                </button>
              </div>
            )}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default DataSync;