import React, { useState, useEffect, useCallback } from 'react';
import { Calendar, Edit, ChevronDown, Users, Clock, Trophy, UserCircle } from 'lucide-react';
import { useAuth } from '../../AuthContext';
import { LoadingSpinner, ErrorMessage, EmptyState, StateMessage } from '../../components/ui/state';
import { 
  getCurrentGameFixtures,
  getMySelections,
  submitSelections,
  getAllSelections,
  getLeaderboard,
  getOverallLeaderboard
} from '../../api/rugby';
import MatchPrediction from '../../components/rugby/MatchPrediction';
import FixtureList from '../../components/rugby/FixtureList';
import Leaderboard from '../../components/rugby/Leaderboard';
import AllSelections from '../../components/rugby/AllSelections';
import { rugbyService } from '../../api/services/game/rugbyService';

const RugbyPage = () => {
  const [currentGame, setCurrentGame] = useState(null);
  const [matches, setMatches] = useState([]);
  const [userSelections, setUserSelections] = useState(null);
  const [allSelections, setAllSelections] = useState([]);
  const [leaderboard, setLeaderboard] = useState([]);
  const [overallLeaderboard, setOverallLeaderboard] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeadlinePassed, setIsDeadlinePassed] = useState(false);
  const { user } = useAuth();
  const [activeTab, setActiveTab] = useState(() => {
    if (!user) return 'selections'; 
    return isDeadlinePassed ? 'leaderboard' : 'selections';
  });

  const isDeadlineExpired = useCallback((deadline) => {
    if (!deadline) return false;
    const now = new Date();
    const deadlineDate = new Date(deadline);
    return now > deadlineDate;
  }, []);

  // Load initial data
  const loadData = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(null);
      
      if (!user) {
        try {
          // Use the public endpoint
          const publicData = await rugbyService.getPublicGameInfo();
          console.log('Public data loaded:', publicData);
          
          setCurrentGame({
            name: publicData.name,
            gameweek: publicData.gameweek,
            deadline: publicData.deadline
          });
          setMatches(publicData.fixtures || []);
          setIsDeadlinePassed(isDeadlineExpired(publicData.deadline));
          
          // Reset authenticated data
          setUserSelections(null);
          setAllSelections([]);
          setLeaderboard([]);
          setOverallLeaderboard([]); 
        } catch (error) {
          console.error('Error loading public data:', error);
          // Set some default values if public data fails to load
          setCurrentGame({
            name: "Rugby Predictions",
            gameweek: "Current Gameweek",
          });
        }
      } else {
        // Authenticated user flow
        const gameData = await rugbyService.getCurrentGame();
        setCurrentGame(gameData.currentGame);
        
        if (gameData.currentGame) {
          const deadlinePassed = isDeadlineExpired(gameData.currentGame.deadline);
          setIsDeadlinePassed(deadlinePassed);
   
          // Load all authenticated data in parallel
          const [
            fixturesData,
            userSelectionsData, 
            allSelectionsData, 
            leaderboardData, 
            overallLeaderboardData
          ] = await Promise.all([
            rugbyService.getCurrentGameFixtures(),
            rugbyService.getMySelections(),
            rugbyService.getAllSelections(),
            rugbyService.getLeaderboard(),
            rugbyService.getOverallLeaderboard()
          ]);
          
          // Update how we set the selections
          setMatches(fixturesData.matches || []);
          setUserSelections(userSelectionsData);
          setAllSelections(allSelectionsData?.data || []); // Use data property
          setLeaderboard(leaderboardData?.data || []);
          setOverallLeaderboard(overallLeaderboardData?.data || []);
        }
      }
    } catch (err) {
      console.error('Error loading data:', err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, [user, isDeadlineExpired]);
   
   useEffect(() => {
    loadData();
   }, [loadData]);

   const handleSubmit = async (predictions) => {
    try {
      setError(null);
      console.log('Submitting predictions to service:', predictions);
      
      // Don't transform the data here - let the service do it
      await rugbyService.submitSelections(predictions);
      
      // Refresh data after submission
      await loadData(); 
      
      setSuccessMessage('Predictions submitted successfully!');
      setIsEditing(false);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
    setSuccessMessage(null);
  };

  const renderHeader = () => (
    <div className="bg-gradient-to-r from-primary-100 to-primary-50 px-4 py-4 border-b border-primary-200">
      <div className="flex justify-between items-start">
        <div>
          <h1 className="text-2xl font-bold text-primary-600">Rugby Predictions</h1>
          <div className="bg-white/80 backdrop-blur-sm rounded-lg px-3 py-2 mt-2">
            <div className="flex items-center gap-2 text-sm text-primary-600">
              <span className="font-medium">{currentGame?.name}</span>
              <span className="text-primary-400">•</span>
              <span className="flex items-center">
                <Calendar className="w-3.5 h-3.5 mr-1" />
                {currentGame?.deadline && new Date(currentGame.deadline).toLocaleString(undefined, {
                  day: 'numeric',
                  month: 'short',
                  hour: '2-digit',
                  minute: '2-digit'
                })}
              </span>
              {allSelections.length > 0 && (
                <>
                  <span className="text-primary-400">•</span>
                  <span className="flex items-center">
                    <Users className="w-3.5 h-3.5 mr-1" />
                    {allSelections.length} Players
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const renderTabs = () => (
    <div className="border-b border-gray-200">
      <div className="max-w-4xl mx-auto px-4">
        <nav className="flex -mb-px space-x-8 overflow-x-auto">
          {user ? (
            <>
              <button
                onClick={() => setActiveTab('selections')}
                className={`py-4 px-1 border-b-2 font-medium text-sm whitespace-nowrap
                  ${activeTab === 'selections'
                  ? 'border-orange-500 text-primary-600' 
                  : 'border-transparent text-gray-500 hover:text-primary-700 hover:border-gray-300'
                }`}
              >
                Selections
              </button>
              <button
                onClick={() => setActiveTab('leaderboard')}
                className={`py-4 px-1 border-b-2 font-medium text-sm whitespace-nowrap
                  ${activeTab === 'leaderboard'
                  ? 'border-orange-500 text-primary-600' 
                  : 'border-transparent text-gray-500 hover:text-primary-700 hover:border-gray-300'
                }`}
              >
                Gameweek
              </button>
              <button
                onClick={() => setActiveTab('overall-leaderboard')}
                className={`py-4 px-1 border-b-2 font-medium text-sm whitespace-nowrap
                  ${activeTab === 'overall-leaderboard'
                  ? 'border-orange-500 text-primary-600' 
                  : 'border-transparent text-gray-500 hover:text-primary-700 hover:border-gray-300'
                }`}
              >
                Overall
              </button>
            </>
          ) : (
            <button
              onClick={() => setActiveTab('selections')}
              className="py-4 px-1 border-b-2 border-orange-500 text-primary-600 font-medium text-sm whitespace-nowrap"
            >
              Selections
            </button>
          )}
        </nav>
      </div>
    </div>
  );

  const renderSelectionsContent = () => {
    if (!user) {
      return (
        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="text-center py-8">
            <UserCircle className="w-16 h-16 mx-auto mb-4 text-primary-600" />
            <h3 className="text-xl font-semibold mb-3">Welcome to {currentGame?.name}</h3>
            <p className="text-gray-600 mb-6 max-w-md mx-auto">
              Join us to predict the scores for {currentGame?.gameweek} and compete with other players.
            </p>
            <div className="space-x-4">
              <a 
                href="/login" 
                className="inline-block px-6 py-2.5 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition-colors"
              >
                Log In
              </a>
              <a 
                href="/register" 
                className="inline-block px-6 py-2.5 border border-primary-600 text-primary-600 rounded-lg hover:bg-primary-50 transition-colors"
              >
                Register
              </a>
            </div>
          </div>
  
          {matches.length > 0 && (
            <div className="mt-8 pt-8 border-t border-gray-100">
              <h4 className="text-lg font-medium text-gray-900 mb-4">{currentGame?.gameweek} Fixtures</h4>
              <FixtureList matches={matches} />
            </div>
          )}
        </div>
      );
    }

    if (isDeadlinePassed) {
      return (
        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-bold">My Predictions</h2>
            <span className="text-amber-600 flex items-center">
              <Clock className="w-4 h-4 mr-1" />
              Deadline Passed
            </span>
          </div>
          
          <MatchPrediction
            matches={matches}
            initialSelections={userSelections?.selections || []}
            isEditing={false}
            disabled={true}
          />
        </div>
      );
    }

    const hasExistingSelections = userSelections && 
    userSelections.selections && 
    userSelections.selections.length > 0;

      return (
        <div className="bg-gradient-to-r from-primary-50 to-white rounded-lg shadow-sm p-6 border border-primary-100">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-bold text-primary-600">Match Predictions</h2>
            {hasExistingSelections && !isEditing && (
              <button
                onClick={handleEdit}
                className="flex items-center px-4 py-2 text-sm font-medium bg-orange-500 text-white
                  rounded-lg hover:bg-orange-600 transition-colors"
              >
                <Edit className="w-4 h-4 mr-2" />
                Edit Predictions
              </button>
            )}
          </div>
      
          <div className="bg-white rounded-lg shadow-sm p-4 border border-primary-50">
          <MatchPrediction
              matches={matches}
              onSubmit={handleSubmit}
              initialSelections={userSelections?.selections || []}
              isEditing={isEditing || !hasExistingSelections}
              disabled={isDeadlinePassed}
            />
          </div>
        </div>
      );
  };

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-primary-50 via-white to-primary-50 overflow-x-hidden">
      {renderHeader()}
      {renderTabs()}
      
      <main className="container mx-auto px-4 py-8">
        <div className="max-w-4xl mx-auto space-y-6">
          {error && error !== 'No auth token provided' && (
            <StateMessage
              type="error"
              message={error}
            />
          )}
          
          {successMessage && (
            <StateMessage
              type="success"
              message={successMessage}
            />
          )}
  
          {!currentGame ? (
            <EmptyState
              title="No Active Gameweek"
              message="There is no active gameweek at the moment. Please check back later."
              icon={<Calendar className="w-12 h-12" />}
                  />
                ) : (
                  <>
              {activeTab === 'selections' && (
                  user ? (
                    isDeadlinePassed ? (
                      <>
                        {console.log('Debug selections data:', {
                          allSelections: allSelections?.[0],
                          user
                        })}
                        <AllSelections
                          matches={matches}
                          selections={allSelections}
                          isDeadlinePassed={isDeadlinePassed}
                          currentUserId={user.displayName} 
                        />
                      </>
                    ) : (
                      renderSelectionsContent()
                    )
                  ) : (
                  // Non-logged in user view
                  <div className="bg-white rounded-lg shadow-sm p-6">
                    <div className="text-center py-8">
                      <UserCircle className="w-16 h-16 mx-auto mb-4 text-primary-600" />
                      <h3 className="text-xl font-semibold mb-3">Welcome to Rugby Predictions</h3>
                      <p className="text-gray-600 mb-6 max-w-md mx-auto">
                        Join us to predict the scores for {currentGame?.gameweek} and compete with other players.
                      </p>
                      <div className="space-x-4">
                        <a 
                          href="/login" 
                          className="inline-block px-6 py-2.5 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition-colors"
                        >
                          Log In
                        </a>
                        <a 
                          href="/register" 
                          className="inline-block px-6 py-2.5 border border-primary-600 text-primary-600 rounded-lg hover:bg-primary-50 transition-colors"
                        >
                          Register
                        </a>
                      </div>
                    </div>
  
                    {matches.length > 0 && (
                      <div className="mt-8 pt-8 border-t border-gray-100">
                        <h4 className="text-lg font-medium text-gray-900 mb-4">{currentGame?.gameweek} Fixtures</h4>
                        <FixtureList matches={matches} />
                      </div>
                    )}
                  </div>
                )
              )}
              {activeTab === 'leaderboard' && user && (
                <Leaderboard
                  data={leaderboard}
                  isLoading={isLoading}
                  onRefresh={loadData}
                  title="Gameweek Leaderboard"
                />
              )}
              {activeTab === 'overall-leaderboard' && user && (
                <Leaderboard
                  data={overallLeaderboard}
                  isLoading={isLoading}
                  onRefresh={loadData}
                  title="Overall Leaderboard"
                />
              )}
            </>
          )}
        </div>
      </main>
    </div>
  );
};

export default RugbyPage;