import React, { useState } from 'react';
import GameManagement from './GameManagement';
import DataSync from './DataSync';
import ScoreManagement from './ScoreManagement';

const RugbyAdmin = () => {
  const [activeTab, setActiveTab] = useState('game');

  const tabs = [
    { id: 'game', name: 'Game Management', Component: GameManagement },
    { id: 'scores', name: 'Score Management', Component: ScoreManagement },
    { id: 'sync', name: 'Data Sync', Component: DataSync }
  ];

  return (
    <div>
      <div className="mb-6">
        <h1 className="text-2xl font-bold text-gray-900">Rugby Administration</h1>
      </div>

      <div className="border-b border-gray-200 mb-6">
        <nav className="flex space-x-8">
          {tabs.map(tab => (
            <button
              key={tab.id}
              onClick={() => setActiveTab(tab.id)}
              className={`py-4 px-1 border-b-2 font-medium text-sm
                ${activeTab === tab.id
                  ? 'border-blue-500 text-blue-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                } transition-colors duration-200`}
            >
              {tab.name}
            </button>
          ))}
        </nav>
      </div>

      <div className="space-y-6">
        {tabs.map(tab => (
          activeTab === tab.id && <tab.Component key={tab.id} />
        ))}
      </div>
    </div>
  );
};

export default RugbyAdmin;