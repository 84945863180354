import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../AuthContext';
import { getGameRoutes } from '../../../routes';
import { NavItem, MobileMenu } from './MenuItems';
import { useFeatureFlags } from '../../../context/FeatureFlagsContext';
import { NavigationLink } from './NavigationLink';
import { layout } from '../../../utils/layout';
import { cn } from '../../../utils/cn';

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();
  const flags = useFeatureFlags();
  const gameRoutes = getGameRoutes();
  const availableGames = gameRoutes
    .filter(route => flags[route.feature])
    .map(route => ({ 
      name: route.path.slice(1).charAt(0).toUpperCase() + route.path.slice(2), 
      path: route.path 
    }));

  return (
    <nav className={layout.colors.primary.main}>
      <div className={cn(layout.container)}>
        <div className={cn('flex justify-between items-center', layout.spacing.nav)}>
          <NavigationLink 
            to="/" 
            className="text-2xl font-bold text-white hover:no-underline"
          >
            {process.env.REACT_APP_HEADER_TITLE || 'Predictions'}
          </NavigationLink>
          
          {/* Desktop Menu */}
          <div className="hidden md:flex items-center space-x-4">
            {availableGames.map(game => (
              <NavigationLink key={game.path} to={game.path}>
                {game.name}
              </NavigationLink>
            ))}
            {user ? (
              <>
                <NavigationLink to="/profile">Profile</NavigationLink>
                {user.isAdmin && <NavigationLink to="/admin">Admin</NavigationLink>}
              </>
            ) : (
              <>
                <NavigationLink to="/login">Login</NavigationLink>
                <NavigationLink to="/register">Register</NavigationLink>
              </>
            )}
          </div>

          {/* Mobile Menu Button */}
          <button 
            className={cn(
              'md:hidden',
              'px-3 py-2 rounded',
              'text-sm font-medium text-slate-200',
              'hover:bg-orange-500 hover:text-white',
              'transition-colors duration-200'
            )}
            onClick={() => setIsOpen(!isOpen)}
            aria-label="Toggle menu"
            aria-expanded={isOpen}
          >
            Menu
          </button>
        </div>

        {/* Mobile Menu */}
        <div 
          className={cn(
            'md:hidden',
            'transition-all duration-200 ease-in-out',
            isOpen ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0 overflow-hidden'
          )}
        >
          <div className="py-2">
          {availableGames.map(game => (
              <NavigationLink 
                key={game.path} 
                to={game.path} 
                isMobile 
                onNavigate={() => setIsOpen(false)}
              >
                {game.name}
              </NavigationLink>
            ))}
            {user ? (
              <>
                <NavigationLink to="/profile" isMobile onNavigate={() => setIsOpen(false)}>
                  Profile
                </NavigationLink>
                {user.isAdmin && (
                  <NavigationLink to="/admin" isMobile onNavigate={() => setIsOpen(false)}>
                    Admin
                  </NavigationLink>
                )}
              </>
            ) : (
              <>
                <NavigationLink to="/login" isMobile onNavigate={() => setIsOpen(false)}>
                  Login
                </NavigationLink>
                <NavigationLink to="/register" isMobile onNavigate={() => setIsOpen(false)}>
                  Register
                </NavigationLink>
              </>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;