import React from 'react';
import { Users } from 'lucide-react';

const AllSelections = ({ selections, currentUserName }) => {
    if (!selections || selections.length === 0) {
        return (
            <div className="bg-white rounded-lg shadow-sm p-6 text-center">
                <Users className="w-12 h-12 mx-auto mb-3 text-gray-400" />
                <p>No selections have been made yet</p>
            </div>
        );
    }

    const getStatusStyles = (selection) => {
        if (selection.hasScored === "true" || selection.hasScored === true) {
            return 'bg-green-50 text-green-700 border-green-100';
        }
        if (selection.hasScored === "false" || selection.hasScored === false) {
            return 'bg-red-50 text-red-700 border-red-100';
        }
        return 'bg-gray-50 text-gray-600 border-gray-100';
    };

    const sortSelections = (selections) => {
        return [...selections].sort((a, b) => {
            const scoreA = calculateUserScore(a.selections);
            const scoreB = calculateUserScore(b.selections);

            // First sort by users who have no incorrect picks
            const aHasIncorrect = scoreA.notScored > 0;
            const bHasIncorrect = scoreB.notScored > 0;
            if (aHasIncorrect !== bHasIncorrect) {
                return aHasIncorrect ? 1 : -1;
            }

            // Then by number of correct picks
            if (scoreA.scored !== scoreB.scored) {
                return scoreB.scored - scoreA.scored;
            }

            // Then by potential maximum achievable
            if (scoreA.potentialMax !== scoreB.potentialMax) {
                return scoreB.potentialMax - scoreA.potentialMax;
            }

            // Finally by username
            return (a.user?.displayName || '').localeCompare(b.user?.displayName || '');
        });
    };

    const calculateUserScore = (selections) => {
        let scored = 0;
        let notScored = 0;
        let pending = 0;

        selections.forEach(selection => {
            if (selection.hasScored === "true" || selection.hasScored === true) scored++;
            else if (selection.hasScored === "false" || selection.hasScored === false) notScored++;
            else pending++;
        });

        return { scored, notScored, pending, potentialMax: scored + pending };
    };

    return (
        <div className="bg-white rounded-lg shadow-sm">
            <div className="border-b border-gray-200">
                <h2 className="text-lg font-bold px-4 py-3">All Selections</h2>
            </div>

            {/* Desktop version */}
            <div className="hidden md:block">
                <table className="w-full">
                    <thead>
                        <tr className="border-b border-gray-100 bg-gray-50">
                            <th className="text-left py-3 px-4 text-sm font-medium text-gray-600">User</th>
                            {[1, 2, 3, 4, 5].map(num => (
                                <th key={num} className="text-left py-3 px-4 text-sm font-medium text-gray-600">
                                    Selection {num}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {sortSelections(selections).map((userSelection) => (
                            <tr 
                                key={userSelection._id} 
                                className={`border-b border-gray-100 ${
                                    userSelection.user.displayName === currentUserName ? 'bg-emerald-50' : ''
                                }`}
                            >
                                <td className="py-3 px-4">
                                    <span className="font-medium">
                                        {userSelection.user.displayName}
                                        {userSelection.user.displayName === currentUserName && ' (You)'}
                                    </span>
                                </td>
                                {userSelection.selections.map((selection, selIndex) => (
                                    <td key={selIndex} className="py-2 px-4">
                                        <div className={`rounded border p-2 ${getStatusStyles(selection)}`}>
                                            <div className="font-medium text-sm">
                                                {selection.player}
                                            </div>
                                            <div className="text-xs mt-0.5 opacity-75">
                                                {selection.team}
                                            </div>
                                        </div>
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Mobile version */}
            <div className="md:hidden">
                {sortSelections(selections).map((userSelection) => (
                    <div 
                        key={userSelection._id} 
                        className={`px-4 py-3 border-b border-gray-100 ${
                            userSelection.user.displayName === currentUserName ? 'bg-emerald-50' : ''
                        }`}
                    >
                        <div className="font-medium mb-2">
                            {userSelection.user.displayName}
                            {userSelection.user.displayName === currentUserName && ' (You)'}
                        </div>
                        <div className="grid grid-cols-3 gap-1.5">
                            {userSelection.selections.map((selection, selIndex) => (
                                <div 
                                    key={selIndex} 
                                    className={`rounded border px-2 py-1.5 text-center ${getStatusStyles(selection)}`}
                                >
                                    <div className="text-sm font-medium">
                                        {selection.player}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AllSelections;