import { useState } from 'react';

export const useRugbyBase = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [syncMessage, setSyncMessage] = useState(null);

  const clearMessages = () => {
    setError(null);
    setSyncMessage(null);
  };

  const handleError = (err, customMessage = '') => {
    const errorMessage = customMessage 
      ? `${customMessage}: ${err.message}`
      : err.message;
    setError(errorMessage);
    throw err;
  };

  return {
    loading,
    error,
    syncMessage,
    setLoading,
    setError,
    setSyncMessage,
    clearMessages,
    handleError
  };
};

export default useRugbyBase;