import React, { useState } from 'react';
import { Star, Loader2 } from 'lucide-react';
import Card from '../../shared/Card';

const PopularPlayersManagement = ({ 
    teams, 
    players, 
    loadingPlayers, 
    updatingPlayer, 
    handleTeamChange, 
    togglePopularPlayer 
}) => {
    console.log('Popular Players Management Component:', {
        teamsCount: teams?.length,
        playersCount: players?.length,
        loadingPlayers,
        updatingPlayer
    });

    const [selectedTeam, setSelectedTeam] = useState('');
    const [changedPlayers, setChangedPlayers] = useState(new Set());

    const handleTeamSelect = async (teamId) => {
        setSelectedTeam(teamId);
        console.log('Team selected:', teamId);
        if (teamId) {
            await handleTeamChange(teamId);
        }
    };

    const handleTogglePlayer = async (player) => {
        await togglePopularPlayer(player._id);
        setChangedPlayers(prev => {
            const newSet = new Set(prev);
            newSet.add(player._id);
            return newSet;
        });
    };

    return (
        <Card>
            <div className="space-y-6">
                <div>
                    <h2 className="text-lg font-semibold mb-4">Popular Players Management</h2>
                    <p className="text-sm text-gray-600 mb-4">
                        Mark players as popular to make them available for quick selection in the game.
                    </p>
                    
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                            Select Team
                        </label>
                        <select
                            value={selectedTeam}
                            onChange={(e) => handleTeamSelect(e.target.value)}
                            className="w-full p-2 border rounded-lg"
                        >
                            <option value="">Select a team</option>
                            {teams?.map((team) => (
                                <option key={team._id} value={team._id}>
                                    {team.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                {loadingPlayers ? (
                    <div className="flex justify-center py-8">
                        <Loader2 className="w-8 h-8 animate-spin text-primary-600" />
                    </div>
                ) : selectedTeam && players?.length > 0 ? (
                    <div className="space-y-2">
                        {players.map((player) => (
                            <div
                                key={player._id}
                                className={`flex items-center justify-between p-3 rounded-lg transition-all duration-200
                                    ${changedPlayers.has(player._id) ? 'bg-green-50' : 'bg-gray-50 hover:bg-gray-100'}`}
                            >
                                <span className="font-medium">{player.name}</span>
                                <button
                                    onClick={() => handleTogglePlayer(player)}
                                    disabled={updatingPlayer === player._id}
                                    className={`p-2 rounded-full transition-colors ${
                                        player.isPopularPick
                                            ? 'bg-yellow-100 text-yellow-600 hover:bg-yellow-200'
                                            : 'bg-gray-100 text-gray-400 hover:bg-gray-200'
                                    }`}
                                >
                                    {updatingPlayer === player._id ? (
                                        <Loader2 className="w-5 h-5 animate-spin" />
                                    ) : (
                                        <Star className={`w-5 h-5 ${player.isPopularPick ? 'fill-current' : ''}`} />
                                    )}
                                </button>
                            </div>
                        ))}
                    </div>
                ) : selectedTeam ? (
                    <div className="text-center py-8 text-gray-500">
                        No players found for this team
                    </div>
                ) : null}
            </div>
        </Card>
    );
};

export default PopularPlayersManagement;