import { useState } from 'react';
import { rugbyAdminService } from '../../api/services';
import useRugbyBase from './useRugbyBase';

export const useDataSync = () => {
  const { loading, error, syncMessage, setError, setSyncMessage, handleError } = useRugbyBase();
  const [lastSyncTime, setLastSyncTime] = useState(null);

  const syncGameweekData = async () => {
    try {
      console.log('Starting rugby sync...');
      setSyncMessage('Syncing gameweek data...');
      const response = await rugbyAdminService.syncData();
      console.log('Sync response:', response);
      setLastSyncTime(new Date());
      setSyncMessage('Gameweek data synced successfully!');
      return response;
    } catch (err) {
      console.error('Sync error:', err);
      handleError(err, 'Failed to sync gameweek data');
    }
  };

  return {
    lastSyncTime,
    loading,
    error,
    syncMessage,
    syncGameweekData
  };
};

export default useDataSync;