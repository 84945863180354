import { useState, useEffect } from 'react';
import { golfAdminService } from '../../api/services';
import useGolfAdminBase from './useGolfAdminBase';

export const usePlayerMapping = () => {
  const { loading, error, syncMessage, setLoading, setError, setSyncMessage, handleError } = useGolfAdminBase();
  const [mappings, setMappings] = useState([]);
  const [unmatchedNames, setUnmatchedNames] = useState([]);

  useEffect(() => {
    fetchMappings();
  }, []);

  const fetchMappings = async () => {
    try {
      setLoading(true);
      const response = await golfAdminService.getPlayerMappings();
      setMappings(response.mappings);
      setUnmatchedNames(response.unmatchedNames);
    } catch (err) {
      handleError(err, 'Failed to fetch player mappings');
    } finally {
      setLoading(false);
    }
  };

  const addMapping = async (canonicalName, variations) => {
    try {
      setSyncMessage('Adding new mapping...');
      await golfAdminService.addPlayerMapping(canonicalName, variations);
      await fetchMappings();
      setSyncMessage('Mapping added successfully!');
    } catch (err) {
      handleError(err, 'Failed to add mapping');
    }
  };

  const removeMapping = async (canonicalName) => {
    try {
      setSyncMessage('Removing mapping...');
      await golfAdminService.removePlayerMapping(canonicalName);
      await fetchMappings();
      setSyncMessage('Mapping removed successfully!');
    } catch (err) {
      handleError(err, 'Failed to remove mapping');
    }
  };

  return {
    mappings,
    unmatchedNames,
    loading,
    error,
    syncMessage,
    addMapping,
    removeMapping,
    refreshMappings: fetchMappings
  };
};

export default usePlayerMapping;