import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/layout/Layout';
import { routes } from './routes';
import { FeatureFlagsProvider, useFeatureFlags } from './context/FeatureFlagsContext';

const AppRoutes = () => {
 const flags = useFeatureFlags();
 return (
   <Routes>
     {routes
       .filter(route => route.alwaysShow || (route.feature && flags[route.feature]))
       .map((route, index) => (
         <Route key={index} path={route.path} element={route.element} />
       ))}
   </Routes>
 );
};

function App() {
 return (
   <Router>
     <FeatureFlagsProvider>
       <Layout>
         <AppRoutes />
       </Layout>
     </FeatureFlagsProvider>
   </Router>
 );
}

export default App;