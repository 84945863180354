import React from 'react';
import { RefreshCw, Users } from 'lucide-react';
import Card from '../../shared/Card';
import useDataSync from '../../../../hooks/football/useDataSync';
import { LoadingSpinner } from '../../../../components/ui/state';

const DataSync = () => {
  const { 
    syncing, 
    syncMatchData,
    syncTeamsAndPlayers
  } = useDataSync();

  return (
    <Card>
      <div className="space-y-6">
        <div>
          <h2 className="text-lg font-semibold mb-4">Sync Football Data</h2>
          
          <div className="grid gap-4 md:grid-cols-2">
            {/* Match Data Sync */}
            <div className="p-4 bg-gray-50 rounded-lg">
              <h3 className="text-md font-medium mb-2">Match Data</h3>
              <p className="text-sm text-gray-600 mb-4">
                Sync fixtures for the upcoming gameweek
              </p>
              <button
                onClick={syncMatchData}
                disabled={syncing}
                className="px-4 py-2 bg-blue-600 text-white rounded-lg 
                  hover:bg-blue-700 transition-colors disabled:bg-blue-300
                  flex items-center gap-2"
              >
                <RefreshCw className={`w-4 h-4 ${syncing ? 'animate-spin' : ''}`} />
                Sync Matches
              </button>
            </div>

            {/* Teams and Players Sync */}
            <div className="p-4 bg-gray-50 rounded-lg">
              <h3 className="text-md font-medium mb-2">Teams & Players</h3>
              <p className="text-sm text-gray-600 mb-4">
                Update team and player data (use with caution)
              </p>
              <button
                onClick={syncTeamsAndPlayers}
                disabled={syncing}
                className="px-4 py-2 bg-amber-600 text-white rounded-lg 
                  hover:bg-amber-700 transition-colors disabled:bg-amber-300
                  flex items-center gap-2"
              >
                <Users className="w-4 h-4" />
                Update Teams & Players
              </button>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default DataSync;