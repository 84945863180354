export const gameTypeColors = {
    football: {
      from: 'from-football-600',
      to: 'to-football-800',
      hover: 'hover:bg-football-700'
    },
    'nfl-lms': {
      from: 'from-nfl-600',
      to: 'to-nfl-800',
      hover: 'hover:bg-nfl-700'
    },
    'nfl-sc': {
      from: 'from-nfl-sc-600',
      to: 'to-nfl-sc-800',
      hover: 'hover:bg-nfl-sc-700'
    },
    default: {
      from: 'from-gray-600',
      to: 'to-gray-800',
      hover: 'hover:bg-gray-700'
    }
  };