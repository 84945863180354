import React from 'react';
import { FooterLink } from './FooterLink';
import { layout } from '../../../utils/layout';

export const FooterSection = ({ title, pages }) => (
  <div>
    <h3 className="font-semibold mb-4 text-white">{title}</h3>
    <ul className={layout.spacing.footer.section}>
      {pages.map(page => (
        <li key={page.id}>
          <FooterLink to={`/${page.slug}`}>
            {page.Title}
          </FooterLink>
        </li>
      ))}
    </ul>
  </div>
);