import React from 'react';
import { StateMessage } from '../../../components/ui/state';
import useFootballBase from '../../../hooks/football/useFootballBase';
import usePopularPlayers from '../../../hooks/football/usePopularPlayers';  // Add this
import DataSync from './DataSync';
import GameManagement from './GameManagement';
import ScoreManagement from './ScoreManagement';
import PopularPlayersManagement from './PopularPlayersManagement';

const FootballAdmin = () => {
  const { currentGame, error, syncMessage, teams } = useFootballBase();  // Add teams to destructuring
  const {
    players,
    loadingPlayers,
    updatingPlayer,
    handleTeamChange,
    togglePopularPlayer
  } = usePopularPlayers();  // Add this hook
  
  console.log('Current game in FootballAdmin:', currentGame);

  return (
    <div className="space-y-6">
      {error && <StateMessage type="error" message={error} />}
      {syncMessage && <StateMessage type="success" message={syncMessage} />}

      <div>
        <h1 className="text-2xl font-bold mb-2">Football Admin</h1>
        {currentGame ? (
          <div className="text-sm text-gray-600">
            Current Game: {currentGame.name}
          </div>
        ) : (
          <div className="text-sm text-amber-600">
            No active game
          </div>
        )}
      </div>

      <DataSync />
      <GameManagement />
      
      {currentGame && (
        <>
          <ScoreManagement />
          <PopularPlayersManagement
            teams={teams}
            players={players}
            loadingPlayers={loadingPlayers}
            updatingPlayer={updatingPlayer}
            handleTeamChange={handleTeamChange}
            togglePopularPlayer={togglePopularPlayer}
          />
        </>
      )}
    </div>
  );
};

export default FootballAdmin;