import BaseApi, { API_ENDPOINTS } from '../../base/baseApi';

class RugbyService extends BaseApi {
    async getCurrentGame() {
        try {
            return await this.get(API_ENDPOINTS.rugby.currentGame);
        } catch (error) {
            console.error('Error getting current game:', error);
            throw error;
        }
    }

      async getPublicGameInfo() {
        try {
            return await this.getPublic(API_ENDPOINTS.rugby.currentGameInfo);
        } catch (error) {
            console.error('Error getting public game info:', error);
            throw error;
        }
    }

    async getCurrentGameFixtures() {
        try {
            const response = await this.get(API_ENDPOINTS.rugby.currentGameFixtures);
            return response || { currentGame: null, matches: [] };
        } catch (error) {
            if (error.message === 'No active game found') {
                return { currentGame: null, matches: [] };
            }
            throw error;
        }
    }

    async getMySelections() {
        try {
            console.log('Fetching my selections...');
            const response = await this.get(API_ENDPOINTS.rugby.mySelections);
            console.log('My selections response:', response);
            
            // Return the selections array directly if it exists
            if (response && response.success && Array.isArray(response.selections)) {
                return {
                    selections: response.selections
                };
            }
            return { selections: [] };
        } catch (error) {
            console.error('Error getting user selections:', error);
            return { selections: [] };
        }
    }

    async submitSelections(selections) {
        try {
            console.log('Raw selections:', selections);
            const formattedSelections = selections.map(sel => {
                console.log('Formatting selection:', sel);
                return {
                    match: sel.matchId,
                    predictedHomeScore: parseInt(sel.homeScore),
                    predictedAwayScore: parseInt(sel.awayScore)
                };
            });
            console.log('Formatted selections for submission:', formattedSelections);
            
            const response = await this.post(API_ENDPOINTS.rugby.selections, {
                selections: formattedSelections
            });
            return response;
        } catch (error) {
            console.error('Error submitting selections:', error);
            throw error;
        }
    }

    async getAllSelections() {
        try {
            const response = await this.get(API_ENDPOINTS.rugby.selections);
            console.log('Rugby selections API raw response:', response);
            return response || []; // Return the response directly since it's already the array we need
        } catch (error) {
            console.error('Error getting all selections:', error);
            return [];
        }
    }

    async getLeaderboard() {
        try {
            const response = await this.get(API_ENDPOINTS.rugby.leaderboard);
            console.log('Leaderboard API response:', response);
            return response || [];  // Return response directly, not response.data
        } catch (error) {
            console.error('Error getting leaderboard:', error);
            return [];
        }
    }

    async getOverallLeaderboard() {
        try {
            // Get the response using the same pattern as the working getLeaderboard method
            const response = await this.get(API_ENDPOINTS.rugby.overallLeaderboard);
            console.log('Overall leaderboard raw response:', response);
            
            // Return the response directly just like getLeaderboard does
            // Don't try to extract data property - the baseApi.get may already be doing that
            return response || [];
        } catch (error) {
            console.error('Error getting overall leaderboard:', error);
            return [];
        }
    }
}

export const rugbyService = new RugbyService();
export { RugbyService };