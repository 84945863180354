import React from 'react';
import { Zap } from 'lucide-react';

const QuickPicks = ({ players, onPlayerSelect, disabled }) => {
    console.log('QuickPicks component - full player data:', players.map(p => ({
        ...p,
        playerId: p.playerId,
        teamId: p.teamId
    })));
    if (!players || players.length === 0) {
        console.log('QuickPicks returning null due to no players');
        return null;
    }

    return (
        <div className="bg-emerald-50 p-4 rounded-lg border border-emerald-100 mb-6">
            <div className="flex items-center gap-2 mb-3">
                <Zap className="w-4 h-4 text-emerald-600" />
                <h3 className="text-lg font-medium text-emerald-700">Quick Picks</h3>
            </div>
            <div className="flex flex-wrap gap-2">
                {players.map(player => (
                    <button
                        key={player.playerId}
                        onClick={() => onPlayerSelect(player)}
                        disabled={disabled}
                        className="px-3 py-1.5 bg-white rounded-full border border-emerald-200 
                            hover:border-emerald-500 hover:bg-emerald-50
                            flex items-center gap-2 text-sm transition-all
                            disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                        <span className="font-medium text-emerald-700">{player.name}</span>
                        <span className="text-emerald-600">({player.team})</span>
                    </button>
                ))}
            </div>
        </div>
    );
};

export default QuickPicks;