import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchStaticPage } from '../../api/services/strapiService';
import LoadingSpinner from '../../components/ui/state/LoadingSpinner';
import ErrorMessage from '../../components/ui/state/ErrorMessage';

const StaticPage = () => {
  const [page, setPage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    const loadPage = async () => {
      try {
        const response = await fetchStaticPage(slug);
        if (response.data?.length > 0) {
          setPage(response.data[0]);
        } else {
          setError('Page not found');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    loadPage();
  }, [slug]);

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorMessage message={error} />;
  if (!page) return <ErrorMessage message="Page not found" />;

  // Helper function to render rich text content
  const renderContent = (content) => {
    if (!Array.isArray(content)) return null;

    return content.map((block, index) => {
      if (block.type === 'paragraph') {
        return (
          <p key={index} className="mb-4">
            {block.children.map((child, childIndex) => (
              <span key={childIndex}>{child.text}</span>
            ))}
          </p>
        );
      }
      return null;
    });
  };

  return (
    <div className="max-w-4xl mx-auto">
      <h1 className="text-3xl font-bold mb-6">{page.Title}</h1>
      <div className="prose max-w-none">
        {renderContent(page.Content)}
      </div>
    </div>
  );
};

export default StaticPage;