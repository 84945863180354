import React from 'react';
import { Link } from 'react-router-dom';
import { cn } from '../../../utils/cn';
import { layout } from '../../../utils/layout';

export const FooterLink = ({ to, children }) => (
  <Link 
    to={to} 
    className={cn(
      layout.colors.footer.text,
      layout.colors.footer.textHover,
      'transition-colors duration-200'
    )}
  >
    {children}
  </Link>
);