import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Calendar, Users, Trophy, Clock } from 'lucide-react';
import { footballService } from '../../api/services';
import moment from 'moment-timezone';
import { useCountdown } from '../../hooks/common/useCountdown';
import { gameTypeColors } from '../../utils/colors';
import { cn } from '../../utils/cn';
import Card from '../common/Card';

// New component for the pattern overlay
const PatternOverlay = () => (
  <div className="absolute inset-0 opacity-10">
    <svg width="100%" height="100%">
      <pattern id="hex-pattern" x="0" y="0" width="16" height="16" patternUnits="userSpaceOnUse">
        <path d="M8 1L15 4.5V11.5L8 15L1 11.5V4.5L8 1Z" stroke="currentColor" fill="none"/>
      </pattern>
      <rect width="100%" height="100%" fill="url(#hex-pattern)"/>
    </svg>
  </div>
);

// New component for game info item
const GameInfoItem = ({ icon: Icon, children }) => (
  <div className="flex items-center text-gray-700">
    <Icon className="w-5 h-5 mr-2 text-gray-500" />
    <span className="font-medium">{children}</span>
  </div>
);

const GameCard = ({ 
  style = 'detailed', 
  showPrizePool = true, 
  primaryColor, 
  game,
  title, 
  deadline, 
  links 
}) => {
  const [entryCount, setEntryCount] = useState(0);
  const navigate = useNavigate();
  
  const gameTitle = game?.title || title;
  const gameDeadline = game?.deadline || deadline;
  
  const timeLeft = useCountdown(gameDeadline);

  const getGameType = () => {
    if (gameTitle?.includes('Football - 5')) return 'football';
    if (gameTitle?.includes('Last Man')) return 'nfl-lms';
    if (gameTitle?.includes('Super Contest')) return 'nfl-sc';
    return 'default';
  };

  useEffect(() => {
    if (getGameType() === 'football') {
      const loadEntryCount = async () => {
        try {
          const count = await footballService.getEntryCount();
          setEntryCount(count);
        } catch (error) {
          console.error('Error fetching entry count:', error);
        }
      };
      loadEntryCount();
    }
  }, [gameTitle]);

  const getBannerStyle = () => {
    const gameType = getGameType();
    const colors = gameTypeColors[gameType];
    return cn('bg-gradient-to-br', colors.from, colors.to);
  };

  const getPrizePool = () => {
    if (game?.prizePool) return game.prizePool;
    
    switch (getGameType()) {
      case 'football':
        return '€825';
      case 'nfl-lms':
        return '£250';
      case 'nfl-sc':
        return '£500';
      default:
        return null;
    }
  };

  const formatDisplayDate = (dateString) => {
    if (!dateString) return '';
    const date = moment.utc(dateString);
    return date.tz('Europe/London').format('dddd, h:mm a z');
  };

  if (style === 'simple') {
    return (
      <Card 
        variant="interactive"
        className="p-6"
      >
        <h3 className="text-xl font-bold mb-4">{gameTitle}</h3>
        {showPrizePool && (
          <GameInfoItem icon={Trophy}>
            Prize Pool: {getPrizePool()}
          </GameInfoItem>
        )}
        {gameDeadline && (
          <div className="space-y-2 mb-4">
            <GameInfoItem icon={Calendar}>
              {formatDisplayDate(gameDeadline)}
            </GameInfoItem>
            {timeLeft && (
              <GameInfoItem icon={Clock}>
                {timeLeft.days > 1 ? 
                  `${timeLeft.days}d` : 
                  `${timeLeft.days * 24 + timeLeft.hours}h ${timeLeft.minutes}m`
                } remaining
              </GameInfoItem>
            )}
          </div>
        )}
        <button 
          onClick={() => navigate(game?.route || links[0]?.to)}
          className={cn(
            'w-full text-white py-2 rounded-lg transition-colors',
            gameTypeColors[getGameType()].hover,
            `bg-${primaryColor || 'purple-600'}`
          )}
        >
          Play Game
        </button>
      </Card>
    );
  }

  return (
    <Card 
      variant="interactive"
      className="overflow-hidden"
    >
      {/* Header Section */}
      <div className={cn('relative h-28 p-6 flex items-center', getBannerStyle())}>
        <PatternOverlay />
        <h3 className="relative text-2xl font-bold text-white leading-tight">
          {gameTitle}
        </h3>
      </div>

      {/* Content */}
      <div className="p-6">
        <div className="space-y-4">
          {showPrizePool && (
            <GameInfoItem icon={Trophy}>
              Prize Pool: {getPrizePool()}
            </GameInfoItem>
          )}

          {getGameType() === 'football' && entryCount > 0 && (
            <GameInfoItem icon={Users}>
              {entryCount} Entries
            </GameInfoItem>
          )}

          {gameDeadline && (
            <div className="space-y-2">
              <GameInfoItem icon={Calendar}>
                {formatDisplayDate(gameDeadline)}
              </GameInfoItem>
              {timeLeft && (
                <GameInfoItem icon={Clock}>
                  {timeLeft.days > 1 ? 
                    `${timeLeft.days}d` : 
                    `${timeLeft.days * 24 + timeLeft.hours}h ${timeLeft.minutes}m`
                  } remaining
                </GameInfoItem>
              )}
            </div>
          )}

          {game?.description && (
            <GameInfoItem icon={Trophy}>
              {game.description}
            </GameInfoItem>
          )}
        </div>

        {/* Action Buttons */}
        <div className="flex flex-wrap gap-2 mt-6">
          {links ? (
            links.map((link, index) => (
              <Link
                key={index}
                to={link.to}
                className={cn(
                  'rounded-lg px-6 py-2 text-sm font-medium text-white transition-colors',
                  gameTypeColors[getGameType()].hover,
                  'bg-purple-600'
                )}
              >
                {link.text}
              </Link>
            ))
          ) : (
            <Link
              to={game.route}
              className={cn(
                'rounded-lg px-6 py-2 text-sm font-medium text-white transition-colors',
                gameTypeColors[getGameType()].hover,
                `bg-${primaryColor || 'purple-600'}`
              )}
            >
              Play Game
            </Link>
          )}
        </div>
      </div>
    </Card>
  );
};

export default GameCard;