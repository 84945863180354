export const API_CONFIG = {
    baseURL: process.env.REACT_APP_API_URL,
    defaultHeaders: {
      'Content-Type': 'application/json',
    },
    timeout: 30000, // 30 seconds
  };
  
  export const GAME_VARIANTS = {
    FOOTBALL: 'football',
    NFL_LMS: 'nfl-lms',
    NFL_SC: 'nfl-sc',
  };
  
  export const ERROR_MESSAGES = {
    NETWORK_ERROR: 'Network error. Please check your connection.',
    UNAUTHORIZED: 'You are not authorized to perform this action.',
    SERVER_ERROR: 'An unexpected server error occurred.',
    NOT_FOUND: 'The requested resource was not found.',
    VALIDATION_ERROR: 'Please check your input and try again.',
  };
  
  export const RESPONSE_MESSAGES = {
    CREATE_SUCCESS: 'Successfully created.',
    UPDATE_SUCCESS: 'Successfully updated.',
    DELETE_SUCCESS: 'Successfully deleted.',
    SYNC_SUCCESS: 'Data synchronized successfully.',
  };