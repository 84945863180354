import BaseApi, { API_ENDPOINTS } from '../../base/baseApi';

console.log('Starting Rugby Admin Service setup...');

class RugbyAdminService extends BaseApi {
    constructor() {
        super();
        console.log('RugbyAdminService constructor called');
        console.log('this instanceof BaseApi:', this instanceof BaseApi);
        console.log('this.get exists:', typeof this.get === 'function');
        console.log('API_ENDPOINTS available:', !!API_ENDPOINTS);
    }

    async getCurrentGame() {
        try {
            console.log('getCurrentGame called');
            console.log('this.get exists in getCurrentGame:', typeof this.get === 'function');
            const response = await this.get(API_ENDPOINTS.admin.rugby.currentGame);
            console.log('Rugby admin service response:', response);
            return response;
        } catch (error) {
            console.error('Error in getCurrentGame:', error);
            throw error;
        }
    }

    async createGame(gameData) {
        try {
            console.log('Creating new gameweek:', gameData);
            const response = await this.post(API_ENDPOINTS.admin.rugby.games, gameData);
            console.log('Game creation response:', response);
            return response;
        } catch (error) {
            console.error('Error creating new game:', error);
            throw error;
        }
    }

    async syncData() {
        try {
            const response = await this.post(API_ENDPOINTS.admin.rugby.sync);
            console.log('Rugby sync response:', response);
            return response;
        } catch (error) {
            console.error('Error syncing rugby data:', error);
            throw error;
        }
    }

    async getGameweekResults() {
        try {
            const response = await this.get(API_ENDPOINTS.admin.rugby.matches);
            return response;
        } catch (error) {
            console.error('Error getting gameweek results:', error);
            throw error;
        }
    }

    async updateMatchScore(matchId, homeScore, awayScore) {
        try {
            const response = await this.post(
                API_ENDPOINTS.admin.rugby.updateScore(matchId),
                { homeScore, awayScore }
            );
            return response;
        } catch (error) {
            console.error('Error updating match score:', error);
            throw error;
        }
    }
}

const rugbyAdminService = new RugbyAdminService();
console.log('Rugby admin service created, checking instance:');
console.log('Instance methods:', Object.getOwnPropertyNames(RugbyAdminService.prototype));
console.log('Instance has get method:', typeof rugbyAdminService.get === 'function');
console.log('Full instance:', rugbyAdminService);

export { rugbyAdminService };